import React, { useState, useEffect } from "react";
import { withStyles, Grid, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import QrReader from "react-qr-reader";
import { connect } from "react-redux";
import Layout from "../../layout";
import { Styles } from "./styles";
import KeyboardIcon from "../../assets/images/icons/Gray_Keyboard.svg";
import ScannerIcon from "../../assets/images/icons/Gray_Inspect Asset.svg";
import Login from "./Login";
import history from "../../constants/history";
import { scanBadge } from "../../store/authentication/action";
import {
  getAccountDetails,
  storeLoginDetails,
  resetMessegaes,
} from "../../store/authentication/action";
import { getAAsset, toResetAAsset } from "../../store/assets/action";
import SnackBar from "../../components/SnackBar";
import Loader from "../../components/Loader";
import { Trans } from "react-i18next";

const LandingPage = ({
  classes,
  match,
  getAccountDetails,
  storeLoginDetails,
  error,
  resetMessegaes,
  getAAsset,
  singleAssetLoading,
  singleAssetError,
  toResetAAsset,
  singleAssetSuccess,
  scanBadge,
}) => {
  const [IsEnterManually, setIsEnterManually] = useState(false);
  const [ShowQR, setShowQR] = useState(false);

  const handleKeyboard = () => {
    setIsEnterManually(true);
  };

  //To show the QR scanner
  const handleScanner = () => {
    setIsEnterManually(false);
  };

  const toDisplayScanner = () => {
    setShowQR(true);
  };

  //To hide scanner
  const toHideScanner = () => {
    setShowQR(false);
  };
  const handleScan = (data) => {
    if (data) {
      let string = "redirect-scan-badge";
      let splitData = data.split("/");
      if (splitData.includes(string)) {
        data = splitData[splitData.length - 1];
      }
      getAAsset(data);
      storeLoginDetails(data);
    }
  };

  useEffect(() => {
    if (singleAssetSuccess) {
      history.push("/scan-badge");
    }
    return () => {
      toResetAAsset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleAssetSuccess]);

  useEffect(() => {
    const urlArray = window.location.href.split("/");
    const type = urlArray[urlArray.length - 2];
    if (type === "redirect-scan-badge") {
      getAAsset(urlArray[urlArray.length - 1]);
    } else if (type === "redirect-scan-badge-user") {
      scanBadge(urlArray[urlArray.length - 1], "asset-list");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleError = (err) => {
    console.error(err);
  };

  //QR Code Styles
  const previewStyle = {
    width: "100%",
  };

  //To get account details
  useEffect(() => {
    if (match) {
      let value = match.params.name;
      getAccountDetails(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseSnackBar = () => {
    resetMessegaes();
    toResetAAsset();
  };

  const handleMessage = (error, singleAssetError) => {
    if (error) {
      return "Account Number or PIN is incorrect";
    } else if (singleAssetError) {
      return "Please scan the correct QR";
    }
  };
  return (
    <div>
      <Layout>
        <div className={ShowQR ? "" : classes.root}>
          {singleAssetLoading && <Loader />}
          {ShowQR && (
            <div className={classes.titleBar}>
              <ArrowBack className={classes.icon} onClick={toHideScanner} />
              <Typography className={classes.text}>
                <Trans>Scan Asset QR</Trans>
              </Typography>
            </div>
          )}
          <Grid container justify="center" alignItems="center">
            <Grid item xs={ShowQR ? 12 : 8} sm={ShowQR ? 12 : 8} md={6}>
              <div className={classes.container}>
                {!ShowQR ? (
                  <div>
                    <Typography className={classes.subTitle}>
                      <Trans>Welcome</Trans>!
                      <span>
                        <Trans>Let's get started</Trans>
                      </span>
                    </Typography>
                    {IsEnterManually ? (
                      <Login />
                    ) : (
                      <div
                        className={classes.content}
                        onClick={toDisplayScanner}
                      >
                        <Typography className={classes.details}>
                          <Trans>
                            Scan the QR Code on the Asset to begin inspection
                          </Trans>
                        </Typography>
                        <div className={classes.scanCover}>
                          <Typography className={classes.scan}>
                            <Trans>Scan</Trans>
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <QrReader
                      delay={2000}
                      style={previewStyle}
                      onError={handleError}
                      onScan={handleScan}
                    />
                    <Typography className={classes.detailsQR}>
                      <Trans>Please scan the QR code on the asset</Trans>
                    </Typography>
                  </>
                )}
                {!ShowQR && (
                  <div className={classes.other}>
                    {IsEnterManually ? (
                      <>
                        <img
                          src={ScannerIcon}
                          alt="Scanner"
                          onClick={handleScanner}
                        />
                        <Typography className={classes.subTitle}>
                          <span>
                            <Trans>Scan QR Code</Trans>
                          </span>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <img
                          src={KeyboardIcon}
                          alt="Keyboard"
                          onClick={handleKeyboard}
                        />
                        <Typography className={classes.subTitle}>
                          <span>
                            <Trans>Enter credentials manually</Trans>
                          </span>
                        </Typography>
                      </>
                    )}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </Layout>
      <SnackBar
        isFailed={error || singleAssetError}
        label={handleMessage(error, singleAssetError)}
        handleClose={handleCloseSnackBar}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  success: state.auth.Success,
  error: state.auth.Error,
  singleAssetLoading: state.asset.SingleAssetLoading,
  singleAssetError: state.asset.SingleAssetError,
  singleAssetSuccess: state.asset.SingleAssetSuccess,
});
const mapDispatchToProps = (dispatch) => ({
  getAccountDetails: (value) => dispatch(getAccountDetails(value)),
  storeLoginDetails: (value) => dispatch(storeLoginDetails(value)),
  resetMessegaes: () => dispatch(resetMessegaes()),
  getAAsset: (id) => dispatch(getAAsset(id)),
  toResetAAsset: () => dispatch(toResetAAsset()),
  scanBadge: (value, url) => dispatch(scanBadge(value, url)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(LandingPage));
