import { createMuiTheme } from "@material-ui/core/styles";

//To create custom theme

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0D5FBE",
      light: "#F2F2F2",
      dark: "#004696",
      contrastText: "#707070",
    },
    secondary: {
      main: "#FFB000",
      light: "#8BC34A",
      dark: "#C99217",
      contrastText: "#79797a",
    },
    info: {
      main: "#11A70B",
      dark: "#137C0F",
      light: "#DD0000",
      contrastText: "#AA0202",
    },
    common: {
      black: "#000",
      white: "#fff",
    },
  },
  typography: {
    fontFamily: [
      "Open Sans",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    // useNextVariants: true,
  },
  props: {
    MuiLink: {
      underline: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 319,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
