export const Styles = (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100vh",
    width: "70%",
    margin: "0 auto",
  },

  message: {
    textAlign: "center",
    color: "#323232",
    fontSize: 20,
  },

  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    marginTop: 25,
    paddingTop: 10,
    paddingBottom: 10,
  },
  popupDialog: {
    "& .MuiPaper-root": {
      backgroundColor: "rgba(255,255,255,0.9)",
    },
  },
});
