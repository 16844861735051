export const Styles = (theme) => ({
  root: {},
  titleBar: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    padding: "12px 5px 6px 15px",
  },
  icon: {
    color: theme.palette.common.white,
    border: "1px solid",
    borderRadius: 50,
    fontSize: 22,
  },
  text: {
    color: theme.palette.common.white,
    fontSize: 16,
    paddingLeft: 10,
    marginTop: -5,
  },
  container: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  other: {
    marginTop: 74,
    textAlign: "center",
    "& img": {
      width: 50,
      cursor: "pointer",
    },
  },
  scan: {
    marginTop: 5,
    color: "#919191",
  },
  assetCard: {
    display: "flex",
    alignItems: "center",
    background: "#F2F2F2 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 12px #00000033",
    borderRadius: 4,
    width: "75%",
    margin: "0 auto",
    padding: 32,
  },
  cardContainer: {
    marginTop: 56,
  },
  assetAvatar: {
    width: 80,
    height: 80,
    marginRight: 10,
  },
  assetName: {
    fontSize: 16,
    color: "#323232",
    fontWeight: 600,
    textAlign: "left",
  },
  assetDetails: {
    fontSize: 14,
    color: "#323232",
    fontWeight: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 200,
  },
  scanBox: {
    width: 84,
    height: 84,
    fontSize: 22,
    textAlign: "center",
    border: "2px dashed #000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.black,
  },
  details: {
    textAlign: "center",
    margin: "0 auto",
    marginTop: 30,
    fontSize: 20,
    width: "80%",
  },
  scanCover: {
    margin: "0 auto",
    marginTop: 50,
    height: 204,
    width: 204,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    background: `linear-gradient(to right, ${theme.palette.primary.main} 2px, transparent 2px) 0 0,
    linear-gradient(to right, ${theme.palette.primary.main} 2px, transparent 2px) 0 100%,
    linear-gradient(to left, ${theme.palette.primary.main} 2px, transparent 2px) 100% 0,
    linear-gradient(to left, ${theme.palette.primary.main} 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, ${theme.palette.primary.main} 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, ${theme.palette.primary.main} 2px, transparent 2px) 100% 0,
    linear-gradient(to top, ${theme.palette.primary.main} 2px, transparent 2px) 0 100%,
    linear-gradient(to top, ${theme.palette.primary.main} 2px, transparent 2px) 100% 100%`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "20px 20px",
  },
});
