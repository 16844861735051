export const Styles = (theme) => ({
  details: {
    color: theme.palette.common.black,
    fontSize: 15,
    fontFamily: "Open Sans",
    marginTop: 22,
    margin: "0 auto",
    width: "60%",
    textAlign: "center",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  root: {
    textAlign: "center",
  },
  error: {
    color: "red",
  },
  card: {
    backgroundColor: theme.palette.primary.light,
    padding: "36px 32px",
    boxShadow: "0px 4px 12px #00000033",
    marginTop: 30,
    width: 250,
  },
  field: {
    backgroundColor: theme.palette.common.white,
    marginBottom: 8,
    borderRadius: 4,
    "& div": {
      height: 48,
      "&:before": {
        content: "none",
      },
    },
    "& input": {
      paddingLeft: 18,
    },
  },
  button: {
    marginTop: 16,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: 48,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  forgot: {
    fontSize: 15,
    color: "#919191",
    marginTop: 28,
    cursor: "pointer",
  },
  contact: {
    fontSize: 17,
    fontFamily: "Open Sans",
    color: "#323232",
    width: "80%",
    margin: "0 auto",
    marginBottom: 35,
  },
  textIcon: {
    marginRight: 10,
    "& svg": {
      color: "#919191",
    },
  },
});
