export const Styles = (theme) => ({
  header: {
    borderTop: `20px solid ${theme.palette.primary.dark}`,
    position: "relative",
  },
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: 56,
    "& img": {
      width: "16%",
      [theme.breakpoints.down("sm")]: {
        width: "34%",
      },
    },
  },
  authContainer: {
    justifyContent: "space-between",
  },
  language: {
    color: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    width: 40,
    height: 40,
    "& img": {
      width: "100%",
    },
  },
  dropMenu: {
    display: "flex",
    alignItems: "center",
    width: "18%",
    "& img": {
      width: "15%",
      marginRight: 4,
      [theme.breakpoints.down("sm")]: {
        width: "34%",
      },
    },
    "& svg": {
      color: theme.palette.common.white,
    },
  },
  dropMenus: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    "& img": {
      width: "20%",
      marginRight: 4,
    },
    "& svg": {
      color: "#000",
    },
  },
  menuLang: {
    color: "#000",
  },
  lang: {
    color: theme.palette.common.white,
  },
  authMargin: {
    marginLeft: "25%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 50,
    },
  },
  dropDown: {
    cursor: "pointer",
  },
});
