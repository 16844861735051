export const Styles = (theme) => ({
  root: {
    textAlign: "center",
    padding: 20,
  },

  title: {
    fontSize: 28,
  },
  content: {
    fontSize: 15,
    margin: "15px 0 25px",
  },
  button: {
    color: "#fff",
    width: "80%",
    fontSize: 15,
  },
});
