export const Styles = (theme) => ({
  titleBar: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    padding: "12px 5px 6px 15px",
  },
  icon: {
    color: theme.palette.common.white,
    border: "1px solid",
    borderRadius: 50,
    fontSize: 22,
  },
  text: {
    color: theme.palette.common.white,
    fontSize: 16,
    paddingLeft: 10,
    marginTop: -5,
  },
  container: {
    width: "90%",
    margin: "0 auto",
  },
  searchField: {
    marginTop: "20px",
    "& div": {
      borderRadius: 50,
    },
  },
  listTitle: {
    fontSize: 20,
    backgroundColor: "#F2F2F2",
    color: "#323232",
    marginTop: 12,
    padding: 10,
  },
  formControl: {
    marginTop: 15,
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  assetList: {
    marginTop: 10,
  },
  status: {
    width: "20px",
    height: "20px",
    borderRadius: 50,
    backgroundColor: "#8BC34A",
    marginRight: 14,
  },
  name: {
    fontSize: 18,
    color: "#323232",
    flex: 1,
  },
  inspect: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: "6px 20px",
    textDecoration: "none",
    fontFamily: "Open Sans",
    borderRadius: 4,
  },
  assetLabel: {
    fontSize: 20,
  },
  menuItem: {
    fontSize: 22,
  },
});
