import {
  LOGIN_USER_LOADING,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  GET_ACCOUNT_DETAILS_LOADING,
  GET_ACCOUNT_DETAILS_SUCCESS,
  GET_ACCOUNT_DETAILS_ERROR,
  UPDATE_PROFILE_LOADING,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  USER_LOGOUT,
  RESET_MESSAGES,
  LOGIN_SCANNER_ACCOUNTID,
  SCAN_BADGE_LOADING,
  SCAN_BADGE_SUCCESS,
  SCAN_BADGE_ERROR,
  LANGUAGE_TRANSLATION,
  GET_INSPECTION_COUNT_LOADING,
  GET_INSPECTION_COUNT_SUCCESS,
  GET_INSPECTION_COUNT_ERROR,
} from "./types";
import BaseURL from "../../constants/APIBaseURL";
import API from "../../constants/APIEndPoints";
import history from "../../constants/history";

//To login the user
const loginLoading = () => ({
  type: LOGIN_USER_LOADING,
});

const loginSuccess = (data) => ({
  type: LOGIN_USER_SUCCESS,
  data,
});

const loginError = (err) => ({
  type: LOGIN_USER_ERROR,
  err,
});

//To login the user using QR
const scanBadgeLoading = () => ({
  type: SCAN_BADGE_LOADING,
});

const scanBadgeSuccess = (data) => ({
  type: SCAN_BADGE_SUCCESS,
  data,
});

const scanBadgeError = (err) => ({
  type: SCAN_BADGE_ERROR,
  err,
});

//To get the account details
const getAccountDetailsLoading = () => ({
  type: GET_ACCOUNT_DETAILS_LOADING,
});

const getAccountDetailsSuccess = (data) => ({
  type: GET_ACCOUNT_DETAILS_SUCCESS,
  data,
});

const getAccountDetailsError = (err) => ({
  type: GET_ACCOUNT_DETAILS_ERROR,
  err,
});

//To update profile
const updateProfileLoading = () => ({
  type: UPDATE_PROFILE_LOADING,
});

const updateProfileSuccess = (data) => ({
  type: UPDATE_PROFILE_SUCCESS,
  data,
});

const updateProfileError = (err) => ({
  type: UPDATE_PROFILE_ERROR,
  err,
});

//To get inspection count
const getInspectionCountLoading = () => ({
  type: GET_INSPECTION_COUNT_LOADING,
});

const getInspectionCountSuccess = (data) => ({
  type: GET_INSPECTION_COUNT_SUCCESS,
  data,
});

const getInspectionCountError = (err) => ({
  type: GET_INSPECTION_COUNT_ERROR,
  err,
});

//To login the user
export const loginUser = (value, type) => {
  return (dispatch, getState) => {
    dispatch(loginLoading());
    let headers = {
      "Content-Type": "application/json",
    };

    // console.log("API called")
    // console.log("DATA", value)
    // console.log("TYPE", type)
    // console.log(getState().asset)

    let accountNo = getState?.()?.asset?.SingleAsset?.[0]?.accountId?.accountNo || getState?.()?.asset?.SingleAsset?.accountId?.accountNo;
    // console.log(accountNo)
    const data = {
      accountNo: type == "QR" ? accountNo : value.AccountID,
      pin: value.PIN,
    };
    // console.log(data)
    BaseURL.post(API.LOGIN, data, {
      headers: headers,
    })
      .then((resp) => {
        dispatch(loginSuccess(resp.data));
        if (type === "QR") {
          history.push("/inspection");
        } else {
          history.push("/asset-list");
        }
      })
      .catch((err) => {
        dispatch(loginError(err));
      });
  };
};

//To login the user using QR
export const scanBadge = (value, redirectUrl) => {
  return (dispatch, getState) => {
    dispatch(scanBadgeLoading());
    let headers = {
      "Content-Type": "application/json",
    };

    let data = {
      operatorId: value,
    };
    BaseURL.post(API.SCAN_BADGE, data, {
      headers: headers,
    })
      .then((resp) => {
        if (resp.data.success) {
          dispatch(scanBadgeSuccess(resp.data));
          if (redirectUrl) {
            history.push(`/${redirectUrl}`);
          } else {
            history.push("/inspection");
          }
        } else {
          dispatch(scanBadgeError(resp));
        }
      })
      .catch((err) => {
        dispatch(scanBadgeError(err));
      });
  };
};

//To get the account details
export const getAccountDetails = (value) => {
  return (dispatch) => {
    dispatch(getAccountDetailsLoading());
    let headers = {
      "Content-Type": "application/json",
    };

    BaseURL.get(`${API.GET_ACCOUNT_DETAILS}/${value}`, {
      headers: headers,
    })
      .then((resp) => {
        dispatch(getAccountDetailsSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(getAccountDetailsError(err));
      });
  };
};

//To update profile
export const updateProfile = (value) => {
  return (dispatch, getState) => {
    dispatch(updateProfileLoading());

    let token = getState().auth.Token;
    let headers = {
      "Content-Type": "multipart/form-data",
      "auth-token": token,
    };

    let userData = {
      fullName: value.FullName,
      phone: value.Phone,
    };

    let formData = new FormData();
    value.SelectedFile && formData.append("profileImg", value.SelectedFile);
    formData.append("userData", JSON.stringify(userData));

    BaseURL.put(API.UPDATE_PROFILE, formData, {
      headers: headers,
    })
      .then((resp) => {
        dispatch(updateProfileSuccess({ value }));
      })
      .catch((err) => {
        dispatch(updateProfileError(err));
      });
  };
};

//to get inspection count
export const getInspectionCount = () => {
  return (dispatch, getState) => {
    dispatch(getInspectionCountLoading());
    let token = getState().auth.Token;
    let headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };

    let accountID =
      getState() &&
      getState().auth &&
      getState().auth.UserDetails &&
      getState().auth.UserDetails.accountId;

    BaseURL.get(`${API.INSPECTION_COUNT}/${accountID}`, {
      headers: headers,
    })
      .then((resp) => {
        dispatch(getInspectionCountSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(getInspectionCountError(err));
      });
  };
};

//For Language Translation
export const changeLanguage = (data) => {
  return {
    type: LANGUAGE_TRANSLATION,
    data,
  };
};

export const logOut = () => {
  localStorage.clear();
  return {
    type: USER_LOGOUT,
  };
};

//To reset the auth state
export const resetMessegaes = () => {
  return {
    type: RESET_MESSAGES,
  };
};

export const storeLoginDetails = (data) => {
  return {
    type: LOGIN_SCANNER_ACCOUNTID,
    data,
  };
};
