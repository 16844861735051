const GET_CHECKLIST_LOADING = "GET_CHECKLIST_LOADING";
const GET_CHECKLIST_SUCCESS = "GET_CHECKLIST_SUCCESS";
const GET_CHECKLIST_ERROR = "GET_CHECKLIST_ERROR";
const POST_ANSWER_LOADING = "POST_ANSWER_LOADING";
const POST_ANSWER_SUCCESS = "POST_ANSWER_SUCCESS";
const POST_ANSWER_ERROR = "POST_ANSWER_ERROR";
const SELECTED_CHECK_LIST = "SELECTED_CHECK_LIST";
const SELECTED_ASSET_CHECK_LIST = "SELECTED_ASSET_CHECK_LIST";
const GET_ASSET_CHECK_LIST_LOADING = "GET_ASSET_CHECK_LIST_LOADING";
const GET_ASSET_CHECK_LIST_SUCCESS = "GET_ASSET_CHECK_LIST_SUCCESS";
const GET_ASSET_CHECK_LIST_ERROR = "GET_ASSET_CHECK_LIST_ERROR";
const TO_STORE_ANSWERS = "TO_STORE_ANSWERS";
const RESET_ALL_STATE = "RESET_ALL_STATE";
const RESET_CHECKLIST_STATE = "RESET_CHECKLIST_STATE";
const UPLOAD_MEDIA_LOADING = "UPLOAD_MEDIA_LOADING";
const UPLOAD_MEDIA_SUCCESS = "UPLOAD_MEDIA_SUCCESS";
const UPLOAD_MEDIA_ERROR = "UPLOAD_MEDIA_ERROR";
const TO_RESET_MEDIA_STATE = "TO_RESET_MEDIA_STATE";

export {
  GET_CHECKLIST_LOADING,
  GET_CHECKLIST_SUCCESS,
  GET_CHECKLIST_ERROR,
  POST_ANSWER_LOADING,
  POST_ANSWER_SUCCESS,
  POST_ANSWER_ERROR,
  SELECTED_CHECK_LIST,
  SELECTED_ASSET_CHECK_LIST,
  GET_ASSET_CHECK_LIST_LOADING,
  GET_ASSET_CHECK_LIST_SUCCESS,
  GET_ASSET_CHECK_LIST_ERROR,
  TO_STORE_ANSWERS,
  RESET_ALL_STATE,
  RESET_CHECKLIST_STATE,
  UPLOAD_MEDIA_LOADING,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_ERROR,
  TO_RESET_MEDIA_STATE,
};
