import {
  GET_CHECKLIST_LOADING,
  GET_CHECKLIST_SUCCESS,
  GET_CHECKLIST_ERROR,
  POST_ANSWER_LOADING,
  POST_ANSWER_SUCCESS,
  POST_ANSWER_ERROR,
  SELECTED_CHECK_LIST,
  SELECTED_ASSET_CHECK_LIST,
  GET_ASSET_CHECK_LIST_LOADING,
  GET_ASSET_CHECK_LIST_SUCCESS,
  GET_ASSET_CHECK_LIST_ERROR,
  TO_STORE_ANSWERS,
  RESET_ALL_STATE,
  RESET_CHECKLIST_STATE,
  UPLOAD_MEDIA_LOADING,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_ERROR,
  TO_RESET_MEDIA_STATE,
} from "./types";
import BaseURL from "../../constants/APIBaseURL";
import API from "../../constants/APIEndPoints";

//To get the checklist details
const getCheckListLoading = () => ({
  type: GET_CHECKLIST_LOADING,
});

const getCheckListSuccess = (data) => ({
  type: GET_CHECKLIST_SUCCESS,
  data,
});

const getCheckListError = (err) => ({
  type: GET_CHECKLIST_ERROR,
  err,
});

//To submit the answers for each question
const postAnswersLoading = () => ({
  type: POST_ANSWER_LOADING,
});

export const postAnswersSuccess = (data) => ({
  type: POST_ANSWER_SUCCESS,
  data,
});

const postAnswersError = (err) => ({
  type: POST_ANSWER_ERROR,
  err,
});

//to get asset checklists by checklist ID
const getAssetCheckListsLoading = () => ({
  type: GET_ASSET_CHECK_LIST_LOADING,
});

const getAssetCheckListsSuccess = (data) => ({
  type: GET_ASSET_CHECK_LIST_SUCCESS,
  data,
});

const getAssetCheckListsError = (err) => ({
  type: GET_ASSET_CHECK_LIST_ERROR,
  err,
});

//To upload multiple media
const toUploadMediaLoading = () => ({
  type: UPLOAD_MEDIA_LOADING,
});

const toUploadMediaSuccess = (data) => ({
  type: UPLOAD_MEDIA_SUCCESS,
  data,
});

const toUploadMediaError = (err) => ({
  type: UPLOAD_MEDIA_ERROR,
  err,
});

//To get the checklist details
export const getCheckList = () => {
  return (dispatch, getState) => {
    dispatch(getCheckListLoading());

    let token = getState()?.auth?.Token;
    let id = getState()?.auth?.AccountDetails?.data._id;
    let headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };

    BaseURL.get(`${API.CHECK_LIST}/${id}`, {
      headers: headers,
    })
      .then((resp) => {
        dispatch(getCheckListSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(getCheckListError(err));
      });
  };
};

//To submit the answers for each question
export const postAnswers = () => {
  return (dispatch, getState) => {
    dispatch(postAnswersLoading());

    let token = getState().auth.Token;
    let headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };

    let data = getState().checkList.Answers;

    BaseURL.post(`${API.SEND_ANSWER}`, data, {
      headers: headers,
    })
      .then((resp) => {
        dispatch(postAnswersSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(postAnswersError(err));
      });
  };
};

//to store selected check list id
export const selectedCheckList = (data) => ({
  type: SELECTED_CHECK_LIST,
  data,
});

//to store selected asset checklists
export const selectedAssetCheckLists = (data) => ({
  type: SELECTED_ASSET_CHECK_LIST,
  data,
});

//to get asset checklists by checklist ID
export const getAssetCheckLists = (id) => {
  return (dispatch, getState) => {
    dispatch(getAssetCheckListsLoading());

    let token = getState().auth.Token;
    let headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };

    BaseURL.get(`${API.GET_CHECKLIST_BY_ID}/${id}`, {
      headers: headers,
    })
      .then((resp) => {
        dispatch(getAssetCheckListsSuccess(resp.data.data));
      })
      .catch((err) => {
        dispatch(getAssetCheckListsError(err));
      });
  };
};

//To upload multiple media
export const toUploadMedia = (data) => {
  return (dispatch, getState) => {
    dispatch(toUploadMediaLoading());

    let token = getState().auth.Token;
    let headers = {
      "Content-Type": "multipart/form-data",
      "auth-token": token,
    };
    let formData = new FormData();
    data.map((file) => formData.append("media", file));
    BaseURL.post(`${API.TO_UPLOAD_MEDIA}`, formData, {
      headers: headers,
    })
      .then((resp) => {
        dispatch(toUploadMediaSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(toUploadMediaError(err));
      });
  };
};

//To reset media state

export const toResetMediaState = () => ({
  type: TO_RESET_MEDIA_STATE,
});

//to store final answers
export const toStoreAnswers = (data) => ({
  type: TO_STORE_ANSWERS,
  data,
});

//To reset checklist state
export const toResetCheckListState = () => ({
  type: RESET_CHECKLIST_STATE,
});

//Reseting the state
export const toResetAllState = () => ({
  type: RESET_ALL_STATE,
});
