import React, { useState } from "react";
import {
  withStyles,
  Typography,
  TextField,
  Card,
  Button,
  InputAdornment,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { connect } from "react-redux";
import { Styles } from "./styles";
import {
  loginUser,
  resetMessegaes,
} from "../../../store/authentication/action";
import Loader from "../../../components/Loader";
import SnackBar from "../../../components/SnackBar";
import { Trans, useTranslation } from "react-i18next";

const Login = ({ classes, loginUser, loading, error, resetMessegaes }) => {
  const [AccountID, setAccountID] = useState("");
  const [PIN, setPIN] = useState("");
  const [IsForgotPin, setIsForgotPin] = useState(false);
  const [IsSubmitted, setIsSubmitted] = useState(false);
  const [ShowPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();

  //To show forgot popup
  const handleForgotPin = () => {
    setIsForgotPin(true);
  };

  //To go back to login screen
  const backToLogin = () => {
    setIsForgotPin(false);
  };

  //To handle the login function and to call the login
  const handleNavigate = () => {
    let data = {
      AccountID,
      PIN,
    };
    setIsSubmitted(true);
    if (AccountID && PIN) {
      loginUser(data);
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!ShowPassword);
  };

  const handleCloseSnackBar = () => {
    resetMessegaes();
  };

  const handleMessage = (error) => {
    if (error) {
      return "Account Number or PIN is incorrect";
    }
  };

  return (
    <div className={classes.root}>
      {loading && <Loader />}
      <Typography className={classes.details}>
        <Trans>Enter your credentials to login and begin inspection</Trans>
      </Typography>
      <Card className={classes.card} elevation={0}>
        {IsForgotPin ? (
          <>
            <Typography className={classes.contact}>
              <Trans>Contact your Manager to request your PIN</Trans>
            </Typography>
            <Button fullWidth className={classes.button} onClick={backToLogin}>
              <Trans>BACK TO LOGIN</Trans>
            </Button>
          </>
        ) : (
          <>
            <TextField
              placeholder={t("Account ID")}
              value={AccountID}
              onChange={(e) => setAccountID(e.target.value)}
              className={classes.field}
              fullWidth
              type="text"
              helperText={
                IsSubmitted && !AccountID && `${t("Account ID is required")}`
              }
              error={IsSubmitted && !AccountID}
            />
            <TextField
              placeholder="PIN"
              value={PIN}
              type={ShowPassword ? "text" : "password"}
              onChange={(e) => setPIN(e.target.value)}
              className={classes.field}
              fullWidth
              helperText={IsSubmitted && !PIN && `${t("PIN is required")}`}
              error={IsSubmitted && !PIN}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className={classes.textIcon}>
                    {ShowPassword ? (
                      <Visibility onClick={handleShowPassword} />
                    ) : (
                      <VisibilityOff onClick={handleShowPassword} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <Button
              fullWidth
              className={classes.button}
              onClick={handleNavigate}
            >
              <Trans>LOGIN</Trans>
            </Button>
            <Typography className={classes.forgot} onClick={handleForgotPin}>
              <Trans>FORGOT PIN</Trans>
            </Typography>
          </>
        )}
      </Card>
      <SnackBar
        isFailed={error}
        label={handleMessage(error)}
        handleClose={handleCloseSnackBar}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.Loading,
  error: state.auth.Error,
});
const mapDispatchToProps = (dispatch) => ({
  loginUser: (value) => dispatch(loginUser(value)),
  resetMessegaes: () => dispatch(resetMessegaes()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(Login));
