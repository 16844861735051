import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import { PhotoCameraOutlined, PhotoLibraryOutlined } from "@material-ui/icons";
import attachmentImage from "../../assets/images/icons/Gray_Attachment.svg";
import { Trans } from "react-i18next";
import Camera from "../../components/Camera";
import "./styles.css";

const SelectImage = ({ handleImageSelect, selectedImages, deleteImage }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [cameraOpen, setCameraOpen] = useState(false);
  let history = useHistory();

  useEffect(() => {
    const disableBackButton = function () {
      if (cameraOpen) {
        setCameraOpen(false);
        history.block();
        return false;
      }
      return true;
    };
    window.addEventListener("popstate", disableBackButton);
    return () => window.removeEventListener("load", disableBackButton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraOpen]);

  return cameraOpen ? (
    <Camera
      onClose={(data) => {
        setCameraOpen(false);
        if (data) {
          handleImageSelect(data);
        }
      }}
    />
  ) : (
    <div className="select-image">
      {isModalOpen && (
        <div className="overlay" onClick={() => setModalOpen(false)}></div>
      )}
      <div className="image-container">
        <div className="image-content">
          <span onClick={() => setModalOpen(true)}>
            <img
              src={
                selectedImages?.length
                  ? selectedImages[selectedImages.length - 1]?.previewImage
                  : attachmentImage
              }
              alt="media"
              className={selectedImages?.length ? "media" : "small"}
            />
          </span>
          {selectedImages?.length && (
            <div>
              <img src={attachmentImage} alt="media" className={"small"} />
            </div>
          )}
          <Typography>
            <Trans>
              {selectedImages?.length &&
                `${selectedImages?.length} file${
                  selectedImages?.length > 1 ? "s" : ""
                } uploaded`}
            </Trans>
          </Typography>
        </div>
        <div>
          {!selectedImages?.length && (
            <Typography>
              <Trans>MEDIA</Trans>
            </Typography>
          )}
        </div>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-containeer">
            <div className="header">
              <p>
                <Trans>Upload Media</Trans>
              </p>
              <div className="buttons">
                <span className="icon" onClick={() => setCameraOpen(true)}>
                  <PhotoCameraOutlined />
                </span>
                <span className="icon">
                  <label htmlFor="contained-button-file">
                    <input
                      accept="image/*"
                      className={"file-input"}
                      id="contained-button-file"
                      type="file"
                      onChange={handleImageSelect}
                      multiple
                    />
                    <PhotoLibraryOutlined />
                  </label>
                </span>
              </div>
            </div>
          </div>
          <div className="image-list-container">
            {selectedImages?.map?.((image, i) => (
              <div className="attachment-container" key={image?.imageUrl}>
                <img
                  src={image?.previewImage}
                  alt="media"
                  className={"image-list"}
                />
                <div className="delete-container">
                  <span className="icon" onClick={() => deleteImage(i)}>
                    <DeleteOutline />
                  </span>
                  <p className="remove-text">Remove</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectImage;
