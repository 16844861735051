export const Styles = (theme) => ({
  container: {
    padding: "8px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  name: {
    fontSize: 20,
  },
  detail: {
    fontSize: 14,
    color: theme.palette.primary.contrastText,
  },
  operator: {
    fontSize: 14,
    textAlign: "center",
    color: "#464646",
    border: "1px solid",
    borderRadius: 50,
    marginTop: 8,
    padding: "4px 30px",
    width: "fit-content",
    textTransform: "capitalize",
  },
  item: {
    borderTop: "1px solid #cdcdcd",
    padding: "15px 20px",
    "& img": {
      width: "25px",
    },
  },
  drawer: {
    marginTop: "40px",
    "& .MuiPaper-root": {
      width: "100%",
    },
  },
  logo: {
    width: 96,
    height: 16,
    position: "absolute",
    bottom: 40,
  },
  content: {
    width: "100%",
    paddingTop: "80px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  lists: {
    flex: 1,
    width: "100%",
  },
  moreIcon: {
    margin: "7px 0 0 -12px",
  },
  closeIcon: {
    fontSize: "30px",
  },
  subItems: {
    backgroundColor: "#F9F9F9",
    paddingLeft: 40,
    "& span": {
      color: "#707070",
      fontSize: 16,
    },
  },
});
