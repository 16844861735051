import React, { useMemo, useState } from "react";
import {
  Typography,
  Card,
  TextField,
  Button,
  withStyles,
} from "@material-ui/core";
import {
  ArrowForwardIos,
  ArrowBackIos,
  ThumbUp,
  ThumbDown,
} from "@material-ui/icons";
import OtpInput from "react-otp-input";
import { Styles } from "./styles";
import { connect } from "react-redux";
import Layout from "../../../layout";
import { OPTIONS } from "../../../constants/data";
import { Trans, useTranslation } from "react-i18next";
import SelectImage from "../../../components/SelectImage";
import callApi from "../../../constants/APIBaseURL";
import APIEndPoints from "../../../constants/APIEndPoints";
import { postAnswersSuccess } from "../../../store/checkList/action";
import history from "../../../constants/history";
import InstructionPopUp from "../../../components/InstructionPopUp";
import config from "../../../utils/config";

import "./question.css";

const isMeterReadingValid = async (assetId, meterReading) => {
  return new Promise((resolve, reject) => {
    callApi
      .get(`${APIEndPoints.VALIDATE_METER_READING}/${assetId}/${meterReading}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
};

const container = {
  marginTop: 11,
  justifyContent: "center",
  padding: 10,
};

const inputStyle = {
  width: 42,
  height: 56,
  margin: "0 4px",
  fontSize: 50,
};

const Questions = ({
  classes,
  checkList,
  selectedCheckList,
  token,
  userDetails,
  currentAsset,
  selectedAssetCheckList,
  postAnswersSuccess,
}) => {
  const { t } = useTranslation();

  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showSubmitView, setShowSubmitView] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInstruction, setShowInstruction] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const [operatorAnswers, setOperatorAnswers] = useState({});

  const currentChecklist = useMemo(
    () => checkList?.filter((list) => list._id === selectedCheckList)[0] ?? {},
    [checkList, selectedCheckList]
  );

  const sectionLength = currentChecklist?.sections?.length;

  const currentSection = useMemo(
    () => currentChecklist?.sections?.[currentSectionIndex],
    [currentChecklist, currentSectionIndex]
  );

  const currentQuestion = useMemo(
    () => currentSection?.questions?.[currentQuestionIndex] ?? {},
    [currentSection, currentQuestionIndex]
  );

  const currentSectionLength = currentSection?.questions?.length ?? 0;

  const sectionNotification = currentSection?.notificationMessage;

  const responseType =
    currentQuestion?.responseTypeId?.responseText === "Meter Reading"
      ? "Meter Reading"
      : currentQuestion?.responseTypeId?.controlType;

  const isMeterReading = responseType === "Meter Reading";

  const handleNextQuestion = async (ans) => {
    console.log(currentAsset)
    if (responseType === "Meter Reading") {
      const currentAssetId = currentAsset?._id || currentAsset?.[0]?._id
      
      let isValidMeterReading = await isMeterReadingValid(
        currentAssetId,
        operatorAnswers[currentQuestion._id]?.response
      );
      if (!isValidMeterReading?.success) {
        setErrorMessage("InvalidMeterReading");
        return;
      } else {
        setErrorMessage(null);
      }
    }
    if (isFieldRequired(ans || operatorAnswers[currentQuestion?._id]?.answer)) {
      return;
    }
    if (currentQuestionIndex < currentSectionLength - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else if (currentSectionIndex < sectionLength - 1) {
      setCurrentQuestionIndex(0);
      setCurrentSectionIndex(currentSectionIndex + 1);
      setShowInstruction(true);
    } else {
      setShowSubmitView(true);
    }
  };

  const isFieldRequired = (ans) => {
    if (ans === OPTIONS[2]) {
      return false;
    }
    let id = currentQuestion?._id;
    let currentAnswer = operatorAnswers[id];
    let isError = false;
    if (responseType === "Meter Reading") {
      if (!currentAnswer?.responseMedia?.length) {
        isError = true;
      }
    }
    if (responseType === OPTIONS[3] || responseType === OPTIONS[4]) {
      if (!currentAnswer?.response) {
        isError = true;
      }
    }
    if (!ans) {
      isError = true;
    }
    return isError;
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    } else if (currentSectionIndex > 0) {
      setShowInstruction(true);
      setCurrentQuestionIndex(
        currentChecklist?.sections?.[currentSectionIndex - 1]?.questions
          ?.length - 1
      );
      setCurrentSectionIndex(currentSectionIndex - 1);
    }
  };

  const handleImage = (e) => {
    if (e?.target?.files[0]) {
      uploadImage(e.target.files[0], currentQuestion?._id);
    } else if (e) {
      uploadImage(e, currentQuestion?._id);
    }
  };

  const uploadImage = (file, questionId) => {
    let headers = {
      "Content-Type": "multipart/form-data",
      "auth-token": token,
    };
    let formData = new FormData();
    formData.append("media", file);
    callApi
      .post(`${APIEndPoints.TO_UPLOAD_MEDIA}`, formData, {
        headers: headers,
      })
      .then(({ data }) => {
        addImageToAnswer(
          questionId,
          data?.data[0]?.filename,
          `${config.mediaUrl}${data?.data[0]?.filename}`
        );
      });
  };

  const addImageToAnswer = (questionId, imageUrl, previewImage) => {
    let obj = { ...(operatorAnswers?.[questionId] ?? {}) };
    obj.responseMedia = Array.isArray(obj.responseMedia)
      ? [...obj.responseMedia, { imageUrl, previewImage }]
      : [{ imageUrl, previewImage }];
    handleAnswer(questionId, obj, false);
  };

  const handleRemoveImage = (i) => {
    const responseMedia = operatorAnswers[
      currentQuestion?._id
    ]?.responseMedia?.filter((_, index) => index !== i);
    let newOperatorAnswers = {
      ...operatorAnswers,
      [currentQuestion?._id]: {
        ...operatorAnswers[currentQuestion?._id],
        responseMedia,
      },
    };
    setOperatorAnswers(newOperatorAnswers);
  };

  const handleAnswer = async (questionId, answer, goNext) => {
    setOperatorAnswers((prevAns) => ({
      ...prevAns,
      [questionId]: {
        ...(prevAns?.[questionId] ?? {}),
        ...answer,
        questionId,
        sectionId: currentChecklist?.sections?.[currentSectionIndex]?._id,
        ...(currentQuestion?.isQuestionMarkAsChecklistFail !== undefined
          ? {
              isfailedResponse: currentQuestion?.isQuestionMarkAsChecklistFail,
            }
          : {}),
        ...(currentQuestion?.failedResponse !== undefined
          ? {
              failedResponse: currentQuestion?.failedResponse,
            }
          : {}),
      },
    }));
    if (goNext) {
      handleNextQuestion(answer?.response || answer?.answer);
    }
  };

  const handleResponseAnswer = (
    questionId,
    answer,
    response,
    goNext,
    isTextField
  ) => {
    let obj = { ...(operatorAnswers?.[questionId] ?? {}) };
    const shouldGoNext =
      answer === OPTIONS[2] ? true : obj.answer && obj.response && goNext;
    if (answer) {
      obj.answer = answer;
    }
    if (isTextField) {
      obj.response = response;
    } else if (response) {
      obj.response = response;
    }
    handleAnswer(questionId, obj, shouldGoNext);
  };

  const prepareAnswers = () => {
    let data = [];
    currentChecklist?.sections?.forEach((section) => {
      data.push({
        sectionId: section._id,
        sectionName: section?.sectionName,
        questions: section?.questions?.map((quest) => {
          let obj = {
            ...operatorAnswers[quest._id],
            responseId: quest.responseTypeId?._id,
            question: quest.questionText,
          };
          if (operatorAnswers[quest._id]?.response) {
            obj.response = obj.response?.toUpperCase?.();
          }
          if (operatorAnswers[quest._id]?.answer) {
            obj.answer = obj.answer?.toUpperCase?.();
          }
          if (operatorAnswers[quest._id]?.responseMedia) {
            obj.responseMedia = obj.responseMedia?.map(
              (media) => media?.imageUrl
            );
          } else {
            obj.responseMedia = null;
          }
          return obj;
        }),
      });
    });
    return data;
  };

  console.log(prepareAnswers());

  const postAnswers = () => {
    let headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };

    console.log("USER DETAILS",userDetails)
    console.log("CURRENT ASSET", currentAsset)
    console.log("SELECTED ASSET", selectedCheckList)
    const data = {
      accountId: userDetails?.accountId,
      inspectorId: userDetails?.userData?._id,
      assetId: currentAsset?._id || currentAsset?.[0]?._id,
      checklistId: selectedCheckList,
      response: prepareAnswers(),
    };

    setIsSubmitting(true);

    callApi
      .post(`${APIEndPoints.SEND_ANSWER}`, data, {
        headers: headers,
      })
      .then(({ data }) => {
        setIsSubmitting(false);
        postAnswersSuccess(data);
        history.push("/result");
      })
      .catch((err) => {
        setIsSubmitting(false);
        window.alert(err);
      });
  };

  const onSubmit = () => postAnswers();

  const onReview = () => setShowSubmitView(false);

  const handleCloseIntruction = () => setShowInstruction(false);

  return (
    <Layout auth={true}>
      <div className="questions">
        {showSubmitView ? (
          <div className="submit-view">
            <Typography className="complete-text">
              <Trans>You've completed the checklist</Trans>
            </Typography>
            <Button
              className="submit-button"
              onClick={onSubmit}
              disabled={isSubmitting}
            >
              <span>
                {isSubmitting ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  "Submit"
                )}
              </span>
            </Button>
            <Button
              className="review-button"
              onClick={onReview}
              disabled={isSubmitting}
            >
              Review responses
            </Button>
          </div>
        ) : (
          <div>
            <div className="notification-container">
              <Card
                className={`section-notification-container ${
                  sectionNotification && "section-bg"
                }`}
              >
                <Typography className="text">
                  {sectionNotification && sectionNotification}
                </Typography>
              </Card>
            </div>
            <div className="row">
              <div className="col-md-4 question-container">
                <div className="row">
                  {currentSectionIndex !== 0 || currentQuestionIndex !== 0 ? (
                    <div
                      className="left-arrow arrow-container"
                      onClick={handlePreviousQuestion}
                    >
                      <ArrowBackIos className="arrow" />
                    </div>
                  ) : null}
                  <Card className="question">
                    <Typography className="title">
                      {currentQuestion?.questionText}
                    </Typography>
                  </Card>
                  <div
                    className="arrow-container right-arrow"
                    onClick={() => handleNextQuestion()}
                  >
                    <ArrowForwardIos className="arrow" />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="bottom-content">
                  <div className="answer-container">
                    {responseType === OPTIONS[4] ? (
                      <div>
                        <TextField
                          label={t("Response")}
                          multiline
                          fullWidth
                          rows="2"
                          value={
                            operatorAnswers[currentQuestion?._id]?.response ??
                            ""
                          }
                          style={{
                            marginBottom: "10px",
                          }}
                          onChange={(e) => {
                            handleResponseAnswer(
                              currentQuestion?._id,
                              null,
                              e.target.value,
                              false,
                              true
                            );
                          }}
                          error={
                            operatorAnswers[currentQuestionIndex?._id]?.response
                          }
                          helperText={
                            !operatorAnswers[currentQuestionIndex?._id]
                              ? `${t("Response is required")}`
                              : ""
                          }
                        />
                        <div className="button-container">
                          <div
                            className={`ring ${
                              operatorAnswers[currentQuestion._id]?.answer ===
                              OPTIONS[1]
                                ? "success"
                                : ""
                            }`}
                          >
                            <ThumbUp
                              className={classes.up}
                              onClick={() =>
                                handleResponseAnswer(
                                  currentQuestion?._id,
                                  OPTIONS[1],
                                  null,
                                  true
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="button-container">
                          <div
                            className={`ring ${
                              operatorAnswers[currentQuestion._id]?.answer ===
                              OPTIONS[2]
                                ? "na"
                                : ""
                            }`}
                          >
                            <Button
                              className={classes.na}
                              onClick={() =>
                                handleResponseAnswer(
                                  currentQuestion?._id,
                                  OPTIONS[2],
                                  null,
                                  true
                                )
                              }
                            >
                              NA
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : responseType === "Meter Reading" ? (
                      <>
                        {isMeterReading && (
                          <Typography className={classes.note}>
                            (
                            <Trans>
                              {errorMessage ||
                                "Please also upload the picture of the hour meter"}
                            </Trans>
                            )
                          </Typography>
                        )}
                        <OtpInput
                          value={
                            operatorAnswers[currentQuestion?._id]?.response ??
                            ""
                          }
                          onChange={(value) => {
                            handleResponseAnswer(
                              currentQuestion?._id,
                              null,
                              value,
                              false,
                              true
                            );
                          }}
                          numInputs={5}
                          containerStyle={container}
                          inputStyle={inputStyle}
                          isInputNum={true}
                        />

                        <div className="button-container">
                          <div
                            className={`ring ${
                              operatorAnswers[currentQuestion._id]?.answer ===
                              OPTIONS[1]
                                ? "success"
                                : ""
                            }`}
                          >
                            <ThumbUp
                              className={classes.up}
                              onClick={() =>
                                handleAnswer(
                                  currentQuestion._id,
                                  { answer: OPTIONS[1] },
                                  operatorAnswers[currentQuestion?._id]
                                    ?.response && true
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="button-container">
                          <div
                            className={`ring ${
                              operatorAnswers[currentQuestion._id]?.answer ===
                              OPTIONS[2]
                                ? "na"
                                : ""
                            }`}
                          >
                            <Button
                              className={`${classes.na} ${classes.flexNA}`}
                              onClick={() =>
                                handleAnswer(
                                  currentQuestion._id,
                                  { response: OPTIONS[2] },
                                  true
                                )
                              }
                            >
                              NA
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : responseType === OPTIONS[3] ? (
                      <>
                        <TextField
                          label={t("Response")}
                          multiline
                          fullWidth
                          rows="2"
                          type="number"
                          min={0}
                          value={
                            operatorAnswers[currentQuestion?._id]?.response ??
                            ""
                          }
                          style={{
                            marginBottom: "10px",
                          }}
                          onChange={(e) => {
                            handleResponseAnswer(
                              currentQuestion?._id,
                              null,
                              e.target.value,
                              false,
                              true
                            );
                          }}
                          error={
                            operatorAnswers[currentQuestionIndex?._id]?.response
                          }
                          helperText={
                            !operatorAnswers[currentQuestionIndex?._id]
                              ? `${t("Response is required")}`
                              : ""
                          }
                        />

                        <div className={`${classes.answers}`}>
                          <div
                            className={`ring ${
                              operatorAnswers[currentQuestion._id]?.answer ===
                              OPTIONS[1]
                                ? "success"
                                : ""
                            }`}
                          >
                            <ThumbUp
                              className={classes.up}
                              onClick={() =>
                                handleAnswer(
                                  currentQuestion._id,
                                  { answer: OPTIONS[1] },
                                  operatorAnswers[currentQuestion?._id]
                                    ?.response && true
                                )
                              }
                            />
                          </div>
                          <div
                            className={`ring ${
                              operatorAnswers[currentQuestion._id]?.answer ===
                              OPTIONS[2]
                                ? "na"
                                : ""
                            }`}
                          >
                            <Button
                              className={`${classes.na} ${classes.flexNA}`}
                              onClick={() =>
                                handleAnswer(
                                  currentQuestion._id,
                                  { response: OPTIONS[2] },
                                  true
                                )
                              }
                            >
                              NA
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div>
                        <div className="button-container">
                          <div
                            className={`ring ${
                              operatorAnswers[currentQuestion._id]?.response ===
                              OPTIONS[1]
                                ? "success"
                                : ""
                            }`}
                          >
                            <ThumbUp
                              className={classes.up}
                              onClick={() =>
                                handleAnswer(
                                  currentQuestion._id,
                                  { response: OPTIONS[1] },
                                  true
                                )
                              }
                            />
                          </div>
                          <div
                            className={`ring ${
                              operatorAnswers[currentQuestion._id]?.response ===
                              OPTIONS[0]
                                ? "danger"
                                : ""
                            }`}
                          >
                            <ThumbDown
                              className={classes.down}
                              onClick={() =>
                                handleAnswer(
                                  currentQuestion._id,
                                  { response: OPTIONS[0] },
                                  true
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="button-container">
                          <div
                            className={`ring ${
                              operatorAnswers[currentQuestion._id]?.response ===
                              OPTIONS[2]
                                ? "na"
                                : ""
                            }`}
                          >
                            <Button
                              className={classes.na}
                              onClick={() =>
                                handleAnswer(
                                  currentQuestion._id,
                                  { response: OPTIONS[2] },
                                  true
                                )
                              }
                            >
                              NA
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    <SelectImage
                      handleImageSelect={handleImage}
                      selectedImages={
                        operatorAnswers[currentQuestion?._id]?.responseMedia
                      }
                      deleteImage={handleRemoveImage}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.statusCover}>
              <div className={classes.status}>
                {currentChecklist?.sections?.[
                  currentSectionIndex
                ]?.questions?.map((quest, i) => (
                  <div
                    onClick={() => setCurrentQuestionIndex(i)}
                    key={quest._id}
                    className={`${classes.state} ${
                      operatorAnswers[quest?._id] ? classes.complete : ""
                    } `}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
        <InstructionPopUp
          open={sectionNotification && showInstruction}
          handleClose={handleCloseIntruction}
          message={sectionNotification}
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  selectedCheckList: state.checkList.SelectedCheckList,
  checkList: state.checkList.AssetCheckList,
  userDetails: state.auth.UserDetails,
  token: state.auth.Token,
  selectedAssetCheckList: state.checkList.SelectedAssetCheckList,
  currentAsset: state.checkList.CurrentAsset,
  mediaLoading: state.checkList.MediaLoading,
});

const mapDispatchToProps = {
  postAnswersSuccess,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(Questions));
