import React, { useState, useEffect } from "react";
import { withStyles, Typography, Grid, Avatar } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Link } from "react-router-dom";
import QrReader from "react-qr-reader";
import { Styles } from "./styles";
import Layout from "../../layout";
import KeyboardIcon from "../../assets/images/icons/Gray_Keyboard.svg";
import ScannerIcon from "../../assets/images/icons/Gray_Inspect Asset.svg";
import Login from "./Login";
import { connect } from "react-redux";
import {
  loginUser,
  resetMessegaes,
  scanBadge,
} from "../../store/authentication/action";
import Loader from "../../components/Loader";
import { selectedAssetCheckLists } from "../../store/checkList/action";
import SnackBar from "../../components/SnackBar";
import { Trans } from "react-i18next";

const ScanBadge = ({
  classes,
  loading,
  singleAsset,
  scanBadge,
  selectedAssetCheckLists,
  scanLoading,
  resetMessegaes,
  scanError,
}) => {
  const [IsEnterManually, setIsEnterManually] = useState(false);
  const [ShowScanner, setShowScanner] = useState(false);

  //To store the scanned details in the Result state
  const handleScan = (data) => {
    if (data) {
      data = data?.split?.("/")[data?.split?.("/").length - 1];
      scanBadge(data);
    }
  };

  useEffect(() => {
    if (singleAsset) {
      selectedAssetCheckLists(singleAsset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleAsset]);

  const showScanner = () => {
    setShowScanner(true);
  };

  const handleError = (err) => {
    console.error(err);
  };

  //To show keyboard
  const handleKeyboard = () => {
    setIsEnterManually(true);
    setShowScanner(true);
  };

  //To show scanner
  const handleScanner = () => {
    setIsEnterManually(false);
  };

  const handleCloseSnackBar = () => {
    resetMessegaes();
  };

  const handleMessage = (scanError) => {
    if (scanError) {
      return "Account Number or PIN is incorrect";
    }
  };

  //QR Code Styles
  const previewStyle = {
    width: "100%",
  };

  let assetName = singleAsset?.[0]?.assetName;
  let assetUID = singleAsset?.[0]?.assetUId;
  let accountID = singleAsset?.[0]?.accountId?.accountNo;
  let image = singleAsset?.[0]?.assetImage;
  return (
    <div>
      <Layout>
        <div className={classes.root}>
          {loading || scanLoading ? <Loader /> : null}
          <div className={classes.titleBar}>
            <Link to="/">
              <ArrowBack className={classes.icon} />
            </Link>
            <Typography className={classes.text}>
              <Trans>Scan Badge</Trans>
            </Typography>
          </div>
          <Grid container justify="center" alignItems="center">
            <Grid
              item
              xs={!IsEnterManually ? 12 : 8}
              sm={!IsEnterManually ? 12 : 8}
              md={6}
            >
              <>
                {!ShowScanner ? (
                  <div className={classes.cardContainer}>
                    <div className={classes.assetCard}>
                      <Avatar
                        className={classes.assetAvatar}
                        src={`https://siera-safety.s3.us-east-2.amazonaws.com/asset/${image}`}
                      />
                      <div>
                        <Typography className={classes.assetName}>
                          {assetName}
                        </Typography>
                        {/* <Typography className={classes.assetDetails}>
                          {assetUID}
                        </Typography> */}
                        <Typography className={classes.assetDetails}>
                          {accountID}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.scanCover} onClick={showScanner}>
                      <Typography className={classes.scanBox}>
                        <Trans>Scan Badge</Trans>
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <div className={classes.container}>
                    {!IsEnterManually ? (
                      <>
                        <QrReader
                          delay={2000}
                          style={previewStyle}
                          onError={handleError}
                          onScan={handleScan}
                        />
                        <Typography className={classes.details}>
                          <Trans>Please scan your user QR code</Trans>
                        </Typography>
                      </>
                    ) : (
                      <Login
                        assetName={assetName}
                        assetUID={assetUID}
                        accountID={accountID}
                        image={image}
                      />
                    )}
                  </div>
                )}
              </>
              <div className={classes.other}>
                {IsEnterManually ? (
                  <>
                    <img
                      src={ScannerIcon}
                      alt="Scanner"
                      onClick={handleScanner}
                    />
                    <Typography className={classes.scan}>
                      <Trans>Scan Your Badge</Trans>
                    </Typography>
                  </>
                ) : (
                  <>
                    <img
                      src={KeyboardIcon}
                      alt="Keyboard"
                      onClick={handleKeyboard}
                    />
                    <Typography className={classes.scan}>
                      <Trans>Enter PIN manually</Trans>
                    </Typography>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </Layout>
      <SnackBar
        isFailed={scanError}
        label={handleMessage(scanError)}
        handleClose={handleCloseSnackBar}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.Loading,
  singleAsset: state.asset.SingleAsset,
  scanLoading: state.auth.ScanLoading,
  scanError: state.auth.ScanError,
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (value, type) => dispatch(loginUser(value, type)),
  scanBadge: (value) => dispatch(scanBadge(value)),
  selectedAssetCheckLists: (value) => dispatch(selectedAssetCheckLists(value)),
  resetMessegaes: () => dispatch(resetMessegaes()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(ScanBadge));
