export const Style = (theme) => ({
  snackBarContent: {
    backgroundColor: "#13d341",
    color: theme.palette.common.white,
  },
  failed: {
    backgroundColor: "#dc004e",
    color: theme.palette.common.white,
  },
});
