import React, { useState, useEffect, useMemo } from "react";
import {
  withStyles,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Search } from "@material-ui/icons";
import { connect } from "react-redux";
import { Styles } from "./styles";
import Layout from "../../layout";
import { getAssetTypes, getAllAsset } from "../../store/assets/action";
import Loader from "../../components/Loader";
import {
  selectedAssetCheckLists,
  toResetCheckListState,
} from "../../store/checkList/action";
import { Trans, useTranslation } from "react-i18next";

const AssetList = ({
  classes,
  getAssetTypes,
  assetTypes,
  getAllAsset,
  assets,
  assetLoading,
  selectedAssetCheckLists,
  toResetCheckListState,
  userDetails,
}) => {
  const [AssetType, setAssetType] = useState("all");
  const [SearchText, setSearchText] = useState("");

  const { t } = useTranslation();

  //To handle asset type
  const handleChange = (e) => {
    setAssetType(e.target.value);
  };

  //To store selected check list
  const selectedCheckLists = (value) => {
    selectedAssetCheckLists(value);
  };

  //To get asset types and all asset on component render
  useEffect(() => {
    toResetCheckListState();
    getAssetTypes();
    getAllAsset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //To render the data without error
  let types = assetTypes && assetTypes.data;

  let worksite = userDetails?.userData?.authorizedSites?.[0]?._id;

  let worksiteAssets = useMemo(
    () => assets?.filter?.((asset) => asset?.siteId?._id === worksite) ?? [],
    [assets, worksite]
  );

  //To filter asset by type
  let filteredAssets = useMemo(
    () =>
      worksiteAssets?.filter((asset) => asset?.assetTypeId?._id === AssetType),
    [worksiteAssets, AssetType]
  );

  let assetsAll = AssetType === "all" ? worksiteAssets : filteredAssets;

  let searchedAssets = useMemo(
    () =>
      assetsAll?.filter(
        (asset) =>
          asset?.assetName?.toLowerCase().indexOf(SearchText?.toLowerCase()) !==
          -1
      ) ?? [],
    [assetsAll, SearchText]
  );

  return (
    <Layout auth={true}>
      <div className={classes.titleBar}>
        {assetLoading && <Loader />}
        <Typography className={classes.text}>
          <Trans>Asset List</Trans>
        </Typography>
      </div>
      <div className={classes.container}>
        <Trans>
          <TextField
            variant="outlined"
            value={SearchText}
            placeholder={t("Search an Asset")}
            className={classes.searchField}
            fullWidth
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Trans>
        <Typography className={classes.listTitle}>
          <Trans>Your Assets</Trans>
        </Typography>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel
            id="demo-simple-select-label"
            className={classes.assetLabel}
          >
            <Trans>Asset Type</Trans>
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={AssetType}
            onChange={handleChange}
            className={classes.menuItem}
          >
            <MenuItem value="all">
              <Trans>All Assets</Trans>
            </MenuItem>
            {types?.map((type) => (
              <MenuItem value={type._id}>{type?.AssetTypeName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={classes.assetList}>
          {searchedAssets?.length > 0 ? (
            searchedAssets?.map((asset) => (
              <div className={classes.listItem}>
                <div className={classes.status} />
                <Typography className={classes.name}>
                  {asset.assetName}
                </Typography>
                <Link
                  to="/inspection"
                  className={classes.inspect}
                  onClick={() => selectedCheckLists(asset)}
                >
                  <Trans>Inspect</Trans>
                </Link>
              </div>
            ))
          ) : (
            <>
              {" "}
              {AssetType === "all" ? (
                <Typography>
                  <Trans>No assets to inspect</Trans>
                </Typography>
              ) : (
                <Typography>
                  <Trans>There are currently 0 assets of this type</Trans>
                </Typography>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  assetTypes: state.asset.AssetTypes,
  assets: state.asset.Assets,
  assetLoading: state.asset.AssetLoading,
  userDetails: state.auth.UserDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getAssetTypes: () => dispatch(getAssetTypes()),
  getAllAsset: () => dispatch(getAllAsset()),
  selectedAssetCheckLists: (data) => dispatch(selectedAssetCheckLists(data)),
  toResetCheckListState: () => dispatch(toResetCheckListState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(AssetList));
