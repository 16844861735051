import React from "react";
import { withStyles, Typography, Avatar } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { connect } from "react-redux";
import { Styles } from "./styles";
import { Link } from "react-router-dom";
import Layout from "../../layout";
import EditImage from "../../assets/images/Blue_Edit.png";
import SnackBar from "../../components/SnackBar";
import { resetMessegaes } from "../../store/authentication/action";
import { Trans } from "react-i18next";

const UserProfile = ({
  classes,
  userDetails,
  updateSuccess,
  resetMessegaes,
}) => {
  //To render the user details without error
  let name =
    userDetails && userDetails.userData && userDetails.userData.fullName;
  let permissions =
    userDetails && userDetails.userData && userDetails.userData.permissions;
  let phone = userDetails && userDetails.userData && userDetails.userData.phone;
  let image =
    userDetails && userDetails.userData && userDetails.userData.imgData;

  const handleCloseSnackBar = () => {
    resetMessegaes();
  };

  const handleMessage = (updateSuccess) => {
    if (updateSuccess) {
      return "Profile updated successfully";
    }
  };
  return (
    <Layout auth={true}>
      <div className={classes.titleBar}>
        <Link to="/asset-list">
          <ArrowBack className={classes.icon} />
        </Link>
        <Typography className={classes.text}>
          <Trans>User Profile</Trans>
        </Typography>
      </div>
      <div className={classes.content}>
        <Avatar
          className={classes.image}
          src={
            image[0] === "b"
              ? image
              : `https://siera-safety.s3.us-east-2.amazonaws.com/user/${image}`
          }
        />
        <Typography className={classes.name}>{name}</Typography>
        <Typography className={classes.job}>
          <Trans>{permissions}</Trans>
        </Typography>
        <div className={classes.details}>
          {/* <Typography className={classes.email}>
            Email<span>{email}</span>
          </Typography> */}
          <Typography className={classes.phone}>
            <Trans>Phone</Trans>
            <span>+1 {phone}</span>
          </Typography>
        </div>
        <Link className={classes.edit} to="/edit-profile">
          <img src={EditImage} alt="edit" />
          <Trans>EDIT</Trans>
        </Link>
      </div>
      <SnackBar
        isSuccess={updateSuccess}
        label={handleMessage(updateSuccess)}
        handleClose={handleCloseSnackBar}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.auth.UserDetails,
  updateSuccess: state.auth.UpdateSuccess,
});
const mapDispatchToProps = (dispatch) => ({
  resetMessegaes: () => dispatch(resetMessegaes()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(UserProfile));
