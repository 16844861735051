import React from "react";
import { withStyles, Dialog, Typography, Button } from "@material-ui/core";
import { Styles } from "./styles";

const ContactPopUp = ({ open, handleClose, classes }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <div className={classes.root}>
        <Typography className={classes.title} color="primary">
          Your inspection limit is exhausted!
        </Typography>
        <Typography className={classes.content}>
          Please contact your account administrator to upgrade your plan in
          order to perform unlimited inspections
        </Typography>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={handleClose}
        >
          GO BACK
        </Button>
      </div>
    </Dialog>
  );
};

export default withStyles(Styles)(ContactPopUp);
