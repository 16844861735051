export const PLAN_LIMIT = {
  price_1I9EwoJF2YbPc3KaLiUt3Gob: {
    maxAllowed: 10,
  },
  price_1I9EwYJF2YbPc3Ka2qdnIyrF: {
    maxAllowed: 1000000000,
  },
  plan_pro: {
    maxAllowed: 1000000000,
  },
};
