import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAAsset } from "../../store/assets/action";
import Loader from "../../components/Loader";

const ScanAsset = ({ history, getAAsset }) => {
  useEffect(() => {
    let locationArray = history.location.pathname.split("/");
    let assetId = locationArray[locationArray.length - 1];
    getAAsset(assetId, "auth");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Loader />
    </div>
  );
};

const mapStateToProps = (state) => ({
  singleAsset: state.asset.SingleAsset,
  singleAssetLoading: state.asset.SingleAssetLoading,
  singleAssetError: state.asset.SingleAssetError,
});

const mapDispatchToProps = (dispatch) => ({
  getAAsset: (id, type) => dispatch(getAAsset(id, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScanAsset);
