import {
  GET_ASSET_TYPE_LOADING,
  GET_ASSET_TYPE_SUCCESS,
  GET_ASSET_TYPE_ERROR,
  GET_ALL_ASSET_LOADING,
  GET_ALL_ASSET_SUCCESS,
  GET_ALL_ASSET_ERROR,
  GET_A_ASSET_LOADING,
  GET_A_ASSET_SUCCESS,
  GET_A_ASSET_ERROR,
  TO_RESET_ALL_ASSET_STATE,
  RESET_A_ASSET_STATE,
} from "./types";
import initialState from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSET_TYPE_LOADING:
      return {
        ...state,
        Loading: true,
        Success: false,
        Error: false,
      };

    case GET_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        Loading: false,
        Success: true,
        Error: false,
        AssetTypes: action.data,
      };

    case GET_ASSET_TYPE_ERROR:
      return {
        ...state,
        Loading: false,
        Success: false,
        Error: true,
      };

    case GET_ALL_ASSET_LOADING:
      return {
        ...state,
        AssetLoading: true,
        AssetSuccess: false,
        AssetError: false,
      };

    case GET_ALL_ASSET_SUCCESS:
      return {
        ...state,
        AssetLoading: false,
        AssetSuccess: true,
        AssetError: false,
        Assets: action.data.data.filter(
          (asset) => asset.assetChecklists.length > 0 && asset.AssetStatus
        ),
      };

    case GET_ALL_ASSET_ERROR:
      return {
        ...state,
        AssetLoading: false,
        AssetSuccess: false,
        AssetError: true,
      };

    case GET_A_ASSET_LOADING:
      return {
        ...state,
        SingleAssetLoading: true,
        SingleAssetSuccess: false,
        SingleAssetError: false,
      };

    case GET_A_ASSET_SUCCESS:
      return {
        ...state,
        SingleAssetLoading: false,
        SingleAssetSuccess: true,
        SingleAssetError: false,
        SingleAsset: action.data.data,
      };

    case GET_A_ASSET_ERROR:
      return {
        ...state,
        SingleAssetLoading: false,
        SingleAssetSuccess: false,
        SingleAssetError: true,
      };

    case RESET_A_ASSET_STATE:
      return {
        ...state,
        SingleAssetLoading: false,
        SingleAssetSuccess: false,
        SingleAssetError: false,
      };

    case TO_RESET_ALL_ASSET_STATE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
