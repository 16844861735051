import { HEADER } from "../../constants/data";

const initialState = {
  Loading: false,
  Success: false,
  Error: false,
  Token: null,
  UserDetails: null,
  AccountLoading: false,
  AccountSuccess: false,
  AccountError: false,
  AccountDetails: null,
  SiteID: null,
  UpdateLoading: false,
  UpdateSuccess: false,
  UpdateError: false,
  AccountID: null,
  ScanLoading: false,
  ScanSuccess: false,
  ScanError: false,
  SelectedLanguage: HEADER[0],
  InspectionCountLoading: false,
  InspectionCountSuccess: false,
  InspectionCountError: false,
  InspectionCount: null,
};

export default initialState;
