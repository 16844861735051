import React, { useState, useEffect } from "react";
import {
  withStyles,
  Typography,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Styles } from "./styles";
import Layout from "../../layout";
import history from "../../constants/history";
import {
  getCheckList,
  selectedCheckList,
  getAssetCheckLists,
  toResetCheckListState,
  selectedAssetCheckLists,
} from "../../store/checkList/action";
import Loader from "../../components/Loader";
import { Trans } from "react-i18next";
import { getInspectionCount } from "../../store/authentication/action";
import ContactPopUp from "../../components/ContactPopUp";
import { PLAN_LIMIT } from "../../constants/planLimit";
import SnackBar from "../../components/SnackBar";

const Inspection = ({
  classes,
  loading,
  userDetails,
  selectedCheckList,
  selectedAssetCheckList,
  getAssetCheckLists,
  assetCheckList,
  getInspectionCount,
  inspectionCount,
  singleAsset,
}) => {
  const [SelectInspection, setSelectInspection] = useState("");
  const [ShowContact, setShowContact] = useState(false);
  const [StartInspect, setStartInspect] = useState(false);

  const handleNavigate = () => {
    if (SelectInspection) {
      history.push("/inspection-questions");
    } else {
      setStartInspect(true);
    }
  };

  useEffect(() => {
    getInspectionCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedAssetCheckList) {
      selectedAssetCheckList.map((list) =>
        getAssetCheckLists(list.checklistId)
      );
    } else if (singleAsset) {
      singleAsset[0] &&
        singleAsset[0].assetChecklists &&
        singleAsset[0].assetChecklists.map((list) =>
          getAssetCheckLists(list.checklistId)
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let defaultList = selectedAssetCheckList?.find(
      (list) => list.isDefaultChecklist
    );

    let defaultSingleList = singleAsset?.[0]?.assetChecklists?.find(
      (list) => list?.isDefaultChecklist
    );
    if (defaultList) {
      let ID = defaultList && defaultList.checklistId;
      setSelectInspection(ID);
      selectedCheckList(ID);
    } else if (defaultSingleList) {
      let id = defaultSingleList && defaultSingleList.checklistId;
      setSelectInspection(id);
      selectedCheckList(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetCheckList]);

  let checkListsTitle = assetCheckList;

  let name = userDetails?.userData?.fullName;

  const handleCheckList = (e) => {
    selectedCheckList(e.target.value);
    setSelectInspection(e.target.value);
  };

  useEffect(() => {
    let id = userDetails?.plan;
    let count = inspectionCount?.count;
    if (PLAN_LIMIT?.[id]?.maxAllowed <= count) {
      setShowContact(true);
    } else {
      setShowContact(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionCount]);

  const handleContact = () => {
    setShowContact(false);
    history.push("/asset-list");
  };

  const handleCloseMessage = () => {
    setStartInspect(false);
  };

  const handleCloseSnackBar = () => {
    handleCloseMessage();
  };

  const handleMessage = (StartInspect) => {
    if (StartInspect) {
      return "Please select a checklist to inspect";
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <Layout auth={true}>
        <div className={classes.container}>
          <Typography className={classes.title}>
            <Trans>Welcome</Trans> {name}!
          </Typography>
          <TextField
            select
            value={SelectInspection}
            onChange={handleCheckList}
            variant="outlined"
            fullWidth
            className={classes.field}
          >
            {checkListsTitle?.map((title) => (
              <MenuItem key={title._id} value={title._id}>
                {title?.checklistName}
              </MenuItem>
            ))}
          </TextField>
          <Button
            className={classes.button}
            onClick={handleNavigate}
            // disabled={!SelectInspection}
          >
            <Trans>START INSPECTION</Trans>
          </Button>
        </div>
      </Layout>
      <ContactPopUp open={ShowContact} handleClose={handleContact} />
      <SnackBar
        isFailed={StartInspect}
        label={handleMessage(StartInspect)}
        handleClose={handleCloseSnackBar}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  checkList: state.checkList.CheckList,
  loading: state.checkList.AssetCheckListLoading,
  userDetails: state.auth.UserDetails,
  selectedAssetCheckList: state.checkList.SelectedAssetCheckList,
  assetCheckList: state.checkList.AssetCheckList,
  inspectionCount: state.auth.InspectionCount,
  singleAsset: state.asset.SingleAsset,
});

const mapDispatchToProps = (dispatch) => ({
  getCheckList: () => dispatch(getCheckList()),
  selectedCheckList: (id) => dispatch(selectedCheckList(id)),
  getAssetCheckLists: (id) => dispatch(getAssetCheckLists(id)),
  toResetCheckListState: () => dispatch(toResetCheckListState()),
  getInspectionCount: () => dispatch(getInspectionCount()),
  selectedAssetCheckLists: (data) => dispatch(selectedAssetCheckLists(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(Inspection));
