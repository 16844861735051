import React from "react";
import { Typography, withStyles, Avatar, Button } from "@material-ui/core";
import Layout from "../../layout";
import { Styles } from "./styles";
import { Link } from "react-router-dom";
import { InfoOutlined } from "@material-ui/icons";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import AssetImage from "../../assets/images/Gray_Asset Photo.png";
import { logOut } from "../../store/authentication/action";
import { toResetAllState } from "../../store/checkList/action";
import { toResetAllAssetState } from "../../store/assets/action";
import { Trans } from "react-i18next";
import inspectionTips from "../../utils/inspectionTips";

const Result = ({
  classes,
  currentAsset,
  userDetails,
  result,
  answerLoading,
  logOut,
  toResetAllState,
  toResetAllAssetState,
}) => {
  let assetName = currentAsset?.assetName;
  let image = currentAsset?.assetImage;

  const getRandomTip = () => {
    return inspectionTips[Math.floor(Math.random() * inspectionTips.length)];
  };

  const handleLogOut = () => {
    logOut();
    toResetAllState();
    toResetAllAssetState();
  };

  let name = userDetails?.userData?.fullName;

  let finalResult = result?.message;

  return (
    <Layout auth={true}>
      {answerLoading ? (
        <Loader />
      ) : (
        <div className={classes.container}>
          {finalResult === "Pass" ? (
            <>
              <Typography className={classes.pass}>
                <Trans>Inspection Pass!</Trans>
              </Typography>
              <Typography className={classes.message}>
                <Trans>Have a good day</Trans> {name}!
              </Typography>
            </>
          ) : (
            <>
              <Typography className={classes.fail}>
                <Trans>Inspection Failed</Trans>!
              </Typography>
              <Typography className={classes.message}>
                <Trans>
                  Please don’t use this asset. Report to your worksite manager
                  for further instructions.
                </Trans>
              </Typography>{" "}
            </>
          )}
          <div className={classes.tipContainer}>
            <div className={classes.leftContent}>
              <InfoOutlined />
            </div>
            <div className={classes.rightContent}>
              <p className={classes.tipHeading}>Tip</p>
              <span className={classes.tipBody}>{getRandomTip()}</span>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.hr} />
            <div className={classes.circle}></div>
            <div className={classes.hr} />
          </div>
          <Avatar
            className={classes.avatar}
            src={`${
              image
                ? `https://siera-safety.s3.us-east-2.amazonaws.com/asset/${image}`
                : AssetImage
            }`}
          />
          <Typography className={classes.name}>{assetName}</Typography>
          <div className={classes.buttons}>
            <Link className={classes.inspection} to="/asset-list">
              <Trans>NEW INSPECTION</Trans>
            </Link>
            <Button
              variant="outlined"
              className={classes.logout}
              color="primary"
              onClick={handleLogOut}
            >
              <Trans>LOGOUT</Trans>
            </Button>
          </div>
        </div>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  currentAsset: state.checkList.CurrentAsset,
  userDetails: state.auth.UserDetails,
  result: state.checkList.Result,
  answerLoading: state.checkList.AnswerLoading,
});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
  toResetAllState: () => dispatch(toResetAllState()),
  toResetAllAssetState: () => dispatch(toResetAllAssetState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(Result));
