import { combineReducers } from "redux";
import AuthReducer from "./authentication";
import AssetReducer from "./assets";
import CheckListReducer from "./checkList";
const reducer = combineReducers({
  auth: AuthReducer,
  asset: AssetReducer,
  checkList: CheckListReducer,
});
export default reducer;
