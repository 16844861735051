const initialState = {
  Loading: false,
  Success: false,
  Error: false,
  CheckList: null,
  AnswerLoading: false,
  AnswerSuccess: false,
  AnswerError: false,
  SelectedCheckList: null,
  SelectedAssetCheckList: null,
  AssetCheckListLoading: false,
  AssetCheckListSuccess: false,
  AssetCheckListError: false,
  AssetCheckList: [],
  CurrentAsset: null,
  Result: null,
  Answers: null,
  MediaLoading: false,
  MediaSuccess: false,
  MediaError: false,
  Media: null,
};

export default initialState;
