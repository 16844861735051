import {
  GET_ASSET_TYPE_LOADING,
  GET_ASSET_TYPE_SUCCESS,
  GET_ASSET_TYPE_ERROR,
  GET_ALL_ASSET_LOADING,
  GET_ALL_ASSET_SUCCESS,
  GET_ALL_ASSET_ERROR,
  GET_A_ASSET_LOADING,
  GET_A_ASSET_SUCCESS,
  GET_A_ASSET_ERROR,
  TO_RESET_ALL_ASSET_STATE,
  RESET_A_ASSET_STATE,
} from "./types";
import BaseURL from "../../constants/APIBaseURL";
import API from "../../constants/APIEndPoints";
import history from "../../constants/history";

//To get all asset types
const getAssetTypeLoading = () => ({
  type: GET_ASSET_TYPE_LOADING,
});

const getAssetTypeSuccess = (data) => ({
  type: GET_ASSET_TYPE_SUCCESS,
  data,
});

const getAssetTypeError = (err) => ({
  type: GET_ASSET_TYPE_ERROR,
  err,
});

//To get all assets details
const getAllAssetLoading = () => ({
  type: GET_ALL_ASSET_LOADING,
});

const getAllAssetSuccess = (data) => ({
  type: GET_ALL_ASSET_SUCCESS,
  data,
});

const getAllAssetError = (err) => ({
  type: GET_ALL_ASSET_ERROR,
  err,
});

//To get asset details by asset ID
const getAAssetLoading = () => ({
  type: GET_A_ASSET_LOADING,
});

const getAAssetSuccess = (data) => ({
  type: GET_A_ASSET_SUCCESS,
  data,
});

const getAAssetError = (err) => ({
  type: GET_A_ASSET_ERROR,
  err,
});

//To get all asset types
export const getAssetTypes = () => {
  return (dispatch, getState) => {
    dispatch(getAssetTypeLoading());
    let token = getState().auth.Token;
    let headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };

    BaseURL.get(API.ASSET_TYPES, {
      headers: headers,
    })
      .then((resp) => {
        dispatch(getAssetTypeSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(getAssetTypeError(err));
      });
  };
};

//To get all assets details
export const getAllAsset = () => {
  return (dispatch, getState) => {
    dispatch(getAllAssetLoading());
    let token = getState().auth.Token;
    let siteID = getState().auth.SiteID;
    let headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };

    BaseURL.get(`${API.GET_ASSETS}/${siteID}`, {
      headers: headers,
    })
      .then((resp) => {
        dispatch(getAllAssetSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(getAllAssetError(err));
      });
  };
};

//To get asset details by asset ID
export const getAAsset = (id, type) => {
  return (dispatch, getState) => {
    dispatch(getAAssetLoading());
    let token = getState().auth.Token;
    let headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };

    BaseURL.get(`${API.GET_ASSET_BY_ID}/${id}`, {
      headers: headers,
    })
      .then((resp) => {
        if (resp.data.success === true) {
          dispatch(getAAssetSuccess(resp.data));
          if (type === "auth") {
            history.push("/inspection");
          }
        } else {
          dispatch(getAAssetError(resp));
        }
      })
      .catch((err) => {
        dispatch(getAAssetError(err));
      });
  };
};

//to Reset All State
export const toResetAllAssetState = () => ({
  type: TO_RESET_ALL_ASSET_STATE,
});

export const toResetAAsset = () => ({
  type: RESET_A_ASSET_STATE,
});
