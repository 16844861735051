import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../constants/history";
import LandingPage from "../views/LandingPage";
import ScanBadge from "../views/ScanBadge";
import Inspection from "../views/Inspection";
import Questions from "../views/Inspection/Questions";
import Result from "../views/Result";
import AssetList from "../views/AssetList";
import UserProfile from "../views/UserProfile";
import EditProfile from "../views/EditProfile";
import ScanAsset from "../views/ScanAsset";
import ProtectedRoute from "./ProtectedRoute";
import Redirect from "../views/LandingPage/Redirect";

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" component={LandingPage} exact />
        <Route path="/site/:name" component={LandingPage} />
        <Route path="/scan-badge" component={ScanBadge} />
        <Route path="/redirect-scan-badge/:assetId" component={Redirect} />
        <Route path="/redirect-scan-badge-user/:id" component={LandingPage} />
        <ProtectedRoute path="/inspection" component={Inspection} />
        <ProtectedRoute path="/inspection-questions" component={Questions} />
        <ProtectedRoute path="/result" component={Result} />
        <ProtectedRoute path="/asset-list" component={AssetList} />
        <ProtectedRoute path="/user-profile" component={UserProfile} />
        <ProtectedRoute path="/edit-profile" component={EditProfile} />
        <ProtectedRoute path="/scan-asset" component={ScanAsset} />{" "}
      </Switch>
    </Router>
  );
};

export default Routes;
