import {
  GET_CHECKLIST_LOADING,
  GET_CHECKLIST_SUCCESS,
  GET_CHECKLIST_ERROR,
  POST_ANSWER_LOADING,
  POST_ANSWER_SUCCESS,
  POST_ANSWER_ERROR,
  SELECTED_CHECK_LIST,
  SELECTED_ASSET_CHECK_LIST,
  GET_ASSET_CHECK_LIST_LOADING,
  GET_ASSET_CHECK_LIST_SUCCESS,
  GET_ASSET_CHECK_LIST_ERROR,
  TO_STORE_ANSWERS,
  RESET_ALL_STATE,
  RESET_CHECKLIST_STATE,
  UPLOAD_MEDIA_LOADING,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_ERROR,
  TO_RESET_MEDIA_STATE,
} from "./types";
import initialState from "./initialState";
import { uniqBy } from "lodash";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHECKLIST_LOADING:
      return {
        ...state,
        Loading: true,
        Success: false,
        Error: false,
      };

    case GET_CHECKLIST_SUCCESS:
      return {
        ...state,
        Loading: false,
        Success: true,
        Error: false,
        CheckList: action.data,
      };

    case GET_CHECKLIST_ERROR:
      return {
        ...state,
        Loading: false,
        Success: false,
        Error: true,
      };

    case POST_ANSWER_LOADING:
      return {
        ...state,
        AnswerLoading: true,
        AnswerSuccess: false,
        AnswerError: false,
      };

    case POST_ANSWER_SUCCESS:
      return {
        ...state,
        AnswerLoading: false,
        AnswerSuccess: true,
        AnswerError: false,
        Result: action.data,
      };

    case POST_ANSWER_ERROR:
      return {
        ...state,
        AnswerLoading: false,
        AnswerSuccess: false,
        AnswerError: true,
      };

    case SELECTED_CHECK_LIST:
      return {
        ...state,
        SelectedCheckList: action.data,
      };

    case SELECTED_ASSET_CHECK_LIST:
      return {
        ...state,
        SelectedAssetCheckList: action.data.assetChecklists,
        CurrentAsset: action.data,
      };

    case GET_ASSET_CHECK_LIST_LOADING:
      return {
        ...state,
        AssetCheckListLoading: true,
        AssetCheckListSuccess: false,
        AssetCheckListError: false,
      };

    case GET_ASSET_CHECK_LIST_SUCCESS:
      return {
        ...state,
        AssetCheckListLoading: false,
        AssetCheckListSuccess: true,
        AssetCheckListError: false,
        AssetCheckList: uniqBy(
          [...state.AssetCheckList, action.data],
          "_id"
        ).filter((list) => list.sections.length > 0),
      };

    case GET_ASSET_CHECK_LIST_ERROR:
      return {
        ...state,
        AssetCheckListLoading: false,
        AssetCheckListSuccess: false,
        AssetCheckListError: true,
      };

    case RESET_CHECKLIST_STATE:
      return {
        ...state,
        AssetCheckListLoading: false,
        AssetCheckListSuccess: false,
        AssetCheckListError: false,
        AssetCheckList: [],
      };

    case UPLOAD_MEDIA_LOADING:
      return {
        ...state,
        MediaLoading: true,
        MediaSuccess: false,
        MediaError: false,
      };

    case UPLOAD_MEDIA_SUCCESS:
      return {
        ...state,
        MediaLoading: false,
        MediaSuccess: true,
        MediaError: false,
        Media: action.data.data,
      };

    case UPLOAD_MEDIA_ERROR:
      return {
        ...state,
        MediaLoading: false,
        MediaSuccess: false,
        MediaError: true,
      };

    case TO_RESET_MEDIA_STATE:
      return {
        ...state,
        MediaLoading: false,
        MediaSuccess: false,
        MediaError: false,
        Media: null,
      };
    case TO_STORE_ANSWERS:
      return {
        ...state,
        Answers: action.data,
      };

    case RESET_ALL_STATE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
