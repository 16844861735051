export const Styles = (theme) => ({
  titleBar: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    padding: "12px 5px 6px 15px",
  },
  icon: {
    color: theme.palette.common.white,
    border: "1px solid",
    borderRadius: 50,
    fontSize: 22,
  },
  text: {
    color: theme.palette.common.white,
    fontSize: 16,
    paddingLeft: 10,
    marginTop: -5,
  },
  content: {
    marginTop: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "75%",
    margin: "0 auto",
  },
  image: {
    height: 117,
    objectFit: "contain",
    width: 117,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageText: {
    color: "#fff",
    fontSize: "10px",
    position: "absolute",
    top: "50%",
    left: "50%",
    "-webkit-transform": "translate(-50%, -50%)",
    "-ms-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  imageContainer: {
    position: "relative",
    height: 117,
    width: 117,
    marginBottom: 50,
    borderRadius: 75,
    "&:hover": {
      "& $overlayImage": {
        height: "45%",
      },
    },
  },
  field: {
    backgroundColor: "#f4f5f7",
    marginBottom: 20,
    "& label": {
      fontSize: 16,
    },
    "& input": {
      fontSize: 18,
      backgroundColor: "transparent",
    },
  },
  save: {
    backgroundColor: "#11A70B",
    color: theme.palette.common.white,
    fontSize: 18,
    borderRadius: 4,
    padding: "10px 30px",
    width: "50%",
    marginBottom: 15,
    "&:hover": {
      backgroundColor: "#11A70B",
    },
  },
  cancel: {
    fontSize: 18,
    padding: "10px 30px",
    width: "50%",
    marginBottom: 15,
  },
  saveImage: {
    width: "58%",
  },
  buttons: {
    marginTop: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  input: {
    display: "none",
  },
  overlayImage: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,0.6)",
    overflow: "hidden",
    width: "100%",
    height: 0,
    transition: ".5s ease",
    borderBottomLeftRadius: "50px",
    borderBottomRightRadius: "50px",
  },
});
