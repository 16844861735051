import React, { useState, Fragment, useEffect } from "react";
import {
  withStyles,
  Drawer,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Collapse,
} from "@material-ui/core";
import { KeyboardArrowRight, CloseOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Styles } from "./styles";
import QRIcon from "../../../assets/images/icons/Gray_Inspect Asset.svg";
import AssetIcon from "../../../assets/images/icons/Gray_Asset List.svg";
import SettingsIcon from "../../../assets/images/icons/Gray_Settings.svg";
import TerminateIcon from "../../../assets/images/icons/TerminateInspection.svg";
import LogoutIcon from "../../../assets/images/icons/Gray_Logout.svg";
import { logOut } from "../../../store/authentication/action";
import { toResetAllState } from "../../../store/checkList/action";
import { toResetAllAssetState } from "../../../store/assets/action";
import { Trans, useTranslation } from "react-i18next";
import history from "../../../constants/history";
import InstructionPopUp from "../../../components/InstructionPopUp";

//Menu List
const MENUS = [
  {
    id: 0,
    label: "Scan/Inspect Asset",
    icon: QRIcon,
    link: "/scan-asset",
    action: false,
    subMenu: false,
  },
  {
    id: 1,
    label: "Asset List",
    icon: AssetIcon,
    link: "/asset-list",
    action: false,
    subMenu: false,
  },
  {
    id: 2,
    label: "Settings",
    icon: SettingsIcon,
    link: "#",
    action: true,
    subMenu: true,
  },
  {
    id: 3,
    label: "Logout",
    icon: LogoutIcon,
    link: "#",
    subMenu: false,
    logout: true,
  },
];

const TerminateMenu = [
  {
    id: 1,
    label: "Terminate Inspection",
    icon: TerminateIcon,
    link: "/asset-list",
    action: false,
    subMenu: false,
  },
  {
    id: 3,
    label: "Logout",
    icon: LogoutIcon,
    link: "#",
    subMenu: false,
    logout: true,
  },
];

const SideDrawerMenu = ({
  classes,
  isDrawerOpen,
  handleDrawer,
  logOut,
  userDetails,
  toResetAllAssetState,
  toResetAllState,
}) => {
  const [IsSettingsOpen, setIsSettingsOpen] = useState(false);
  const [menu, setMenu] = useState(MENUS);
  const [showLogoutConfirmation, toggleLogoutConfirmation] = useState(false);

  useEffect(() => {
    if (window.location.hash.split("/")[1] === "inspection-questions") {
      setMenu(TerminateMenu);
    } else {
      setMenu(MENUS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  const handleNavigation = (link) => {
    handleDrawer(false);
    history.push(link);
  };

  const { t } = useTranslation();

  //To toggle settings menu
  const handleSettings = () => {
    setIsSettingsOpen(!IsSettingsOpen);
  };

  //To handle logout
  const handleLogOut = () => {
    toggleLogoutConfirmation(false);
    logOut();
    toResetAllState();
    toResetAllAssetState();
  };

  const toggleLogout = () => {
    toggleLogoutConfirmation(true);
  };

  let name = userDetails?.userData?.fullName;
  let role = userDetails?.userData?.permissions;
  return (
    <Drawer
      anchor="left"
      open={isDrawerOpen}
      onClose={handleDrawer}
      className={`${classes.drawer} drawer`}
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <div>
            <Typography className={classes.name}>{name}</Typography>
            <Typography className={classes.operator}>
              <Trans>{role}</Trans>
            </Typography>
          </div>
          <div onClick={handleDrawer}>
            <CloseOutlined className={classes.closeIcon} />
          </div>
        </div>
      </div>
      <List className={classes.lists}>
        {menu.map((menu) => (
          <Fragment key={menu.id}>
            <ListItem
              className={classes.item}
              disableGutters
              button
              component={Link}
              to={menu.link}
              onClick={
                menu.action
                  ? handleSettings
                  : menu.logout
                  ? toggleLogout
                  : () => handleNavigation(menu.link)
              }
            >
              <ListItemIcon>
                <img src={menu.icon} alt={menu.label} />
              </ListItemIcon>
              <Trans>
                <ListItemText primary={t(menu.label)} />
              </Trans>
              <KeyboardArrowRight className={classes.moreIcon} />
            </ListItem>
            <Collapse
              in={IsSettingsOpen && menu.id === 2}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding className={classes.subItems}>
                <ListItem
                  disableGutters
                  button
                  component={Link}
                  to="/user-profile"
                >
                  <ListItemText primary={t("User Profile")} />
                </ListItem>
              </List>
            </Collapse>
          </Fragment>
        ))}
      </List>
      <InstructionPopUp
        open={showLogoutConfirmation}
        handleClose={() => toggleLogoutConfirmation(false)}
        message={"Are you sure you want to logout?"}
        confirmMessage="YES"
        declineMessage="CANCEL"
        handleConfirm={handleLogOut}
      />
    </Drawer>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
  toResetAllState: () => dispatch(toResetAllState()),
  toResetAllAssetState: () => dispatch(toResetAllAssetState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(SideDrawerMenu));
