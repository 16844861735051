import React from "react";
import { withStyles, Dialog, Typography, Button } from "@material-ui/core";
import { Styles } from "./styles";
import { Trans } from "react-i18next";

const InstructionPopUp = ({
  classes,
  open,
  handleClose,
  message,
  confirmMessage,
  declineMessage,
  handleConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      className={classes.popupDialog}
    >
      <div className={classes.container}>
        <Typography className={classes.message}>{message}</Typography>
        <Button
          onClick={handleConfirm ? handleConfirm : handleClose}
          className={classes.button}
          fullWidth
        >
          <Trans>{confirmMessage ? confirmMessage : "CONTINUE"}</Trans>
        </Button>
        {declineMessage && (
          <Button onClick={handleClose}>
            <Trans>{declineMessage}</Trans>
          </Button>
        )}
      </div>
    </Dialog>
  );
};

export default withStyles(Styles)(InstructionPopUp);
