import {
  LOGIN_USER_LOADING,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  GET_ACCOUNT_DETAILS_LOADING,
  GET_ACCOUNT_DETAILS_SUCCESS,
  GET_ACCOUNT_DETAILS_ERROR,
  UPDATE_PROFILE_LOADING,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  USER_LOGOUT,
  RESET_MESSAGES,
  LOGIN_SCANNER_ACCOUNTID,
  SCAN_BADGE_LOADING,
  SCAN_BADGE_SUCCESS,
  SCAN_BADGE_ERROR,
  LANGUAGE_TRANSLATION,
  GET_INSPECTION_COUNT_LOADING,
  GET_INSPECTION_COUNT_SUCCESS,
  GET_INSPECTION_COUNT_ERROR,
} from "./types";
import initialState from "./initialState";
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_LOADING:
      return {
        ...state,
        Loading: true,
        Success: false,
        Error: false,
      };

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        Loading: false,
        Success: true,
        Error: false,
        Token: action.data.token,
        UserDetails: action.data,
        SiteID: action.data.userData.authorizedSites[0]?._id,
      };

    case LOGIN_USER_ERROR:
      return {
        ...state,
        Loading: false,
        Success: false,
        Error: true,
      };

    case SCAN_BADGE_LOADING:
      return {
        ...state,
        ScanLoading: true,
        ScanSuccess: false,
        ScanError: false,
      };

    case SCAN_BADGE_SUCCESS:
      return {
        ...state,
        ScanLoading: false,
        ScanSuccess: true,
        ScanError: false,
        Token: action.data.token,
        UserDetails: {
          ...action.data,
          userData: {
            ...action.data.userData,
            authorizedSites: [
              {
                _id: action.data.userData.authorizedSites[0],
              },
            ],
          },
        },
        SiteID: action.data.userData.authorizedSites[0],
      };

    case SCAN_BADGE_ERROR:
      return {
        ...state,
        ScanLoading: false,
        ScanSuccess: false,
        ScanError: true,
      };

    case GET_ACCOUNT_DETAILS_LOADING:
      return {
        ...state,
        AccountLoading: true,
        AccountSuccess: false,
        AccountError: false,
      };

    case GET_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        AccountLoading: false,
        AccountSuccess: true,
        AccountError: false,
        AccountDetails: action.data,
      };

    case GET_ACCOUNT_DETAILS_ERROR:
      return {
        ...state,
        AccountLoading: false,
        AccountSuccess: false,
        AccountError: true,
      };

    case UPDATE_PROFILE_LOADING:
      return {
        ...state,
        UpdateLoading: true,
        UpdateSuccess: true,
        UpdateError: false,
      };

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        UpdateLoading: false,
        UpdateSuccess: true,
        UpdateError: false,
        UserDetails: {
          ...state.UserDetails,
          userData: {
            ...state.UserDetails.userData,
            fullName: action.data.value.FullName,
            phone: action.data.value.Phone,
            imgData: action.data.value.PreviewImage,
          },
        },
      };

    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        UpdateLoading: false,
        UpdateSuccess: false,
        UpdateError: true,
      };

    case USER_LOGOUT:
      return initialState;

    case RESET_MESSAGES:
      return {
        ...state,
        Loading: false,
        Success: false,
        Error: false,
        UpdateLoading: false,
        UpdateSuccess: false,
        UpdateError: false,
        ScanLoading: false,
        ScanSuccess: false,
        ScanError: false,
      };

    case LOGIN_SCANNER_ACCOUNTID:
      return {
        ...state,
        AccountID: action.data,
      };

    case LANGUAGE_TRANSLATION:
      return {
        ...state,
        SelectedLanguage: action.data,
      };

    case GET_INSPECTION_COUNT_LOADING:
      return {
        ...state,
        InspectionCountLoading: true,
        InspectionCountSuccess: false,
        InspectionCountError: false,
      };

    case GET_INSPECTION_COUNT_SUCCESS:
      return {
        ...state,
        InspectionCountLoading: false,
        InspectionCountSuccess: true,
        InspectionCountError: false,
        InspectionCount: action.data,
        UserDetails: { ...state.UserDetails, plan: action.data.plan },
      };

    case GET_INSPECTION_COUNT_ERROR:
      return {
        ...state,
        InspectionCountLoading: false,
        InspectionCountSuccess: false,
        InspectionCountError: true,
      };

    default:
      return state;
  }
};

export default reducer;
