export const Styles = (theme) => ({
  up: {
    color: theme.palette.common.white,
    background: `radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%), linear-gradient(to top right, ${theme.palette.info.main} 100%, ${theme.palette.info.dark} 0%)`,
    borderRadius: "225px",
    boxShadow: "0px 6px 20px rgba(0,0,0,0.6)",
    fontSize: 30,
    padding: 45,
  },
  down: {
    color: theme.palette.common.white,
    background: `radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%), linear-gradient(to top right, ${theme.palette.info.light} 100%, ${theme.palette.info.contrastText} 0%)`,
    borderRadius: "225px",
    boxShadow: "0px 6px 20px rgba(0,0,0,0.6)",
    fontSize: 30,
    padding: 45,
  },
  na: {
    color: "#919191",
    background: `radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%), linear-gradient(to top right, #F2F2F2 100%, #C6C6C6 0%)`,
    borderRadius: "225px",
    boxShadow: "0px 6px 20px rgba(0,0,0,0.6)",
    fontSize: 20,
    padding: "25px 28.5px",
  },
  answers: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  status: {
    display: "flex",
    paddingTop: 5,
    margin: "0 2px",
  },
  statusCover: {
    backgroundColor: theme.palette.common.white,
    position: "fixed",
    bottom: 0,
    width: "100%",
    height: 15,
  },
  state: {
    height: 5,
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    opacity: 0.2,
    marginRight: 4,
  },
  complete: {
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
  },
});
