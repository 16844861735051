const APIEndPoints = {
  LOGIN: "apiv2/user/operatorSignin",
  GET_ACCOUNT_DETAILS: "apiv2/account/byEnterpriseUrl",
  ASSET_TYPES: "apiv2/assetType/filter/assetTypeList",
  CHECK_LIST: "apiv2/checklist/byAccount",
  GET_ASSETS: "apiv2/asset/getallassets",
  UPDATE_PROFILE: "apiv2/user/update/profile",
  SEND_ANSWER: "apiv2/checklist/checklistResponse",
  GET_CHECKLIST_BY_ID: "apiv2/checklist",
  GET_ASSET_BY_ID: "apiv2/asset/getassetforqr",
  SCAN_BADGE: "apiv2/user/operatorScanLogin",
  TO_UPLOAD_MEDIA: "apiv2/checklist/uploadMedia",
  INSPECTION_COUNT: "apiv2/checklist/inspectionCount",
  VALIDATE_METER_READING: "apiv2/checklist/validateMeterReading",
};

export default APIEndPoints;
