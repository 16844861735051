import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  withStyles,
  Avatar,
  Typography,
  Menu,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Styles } from "./styles";
import Logo from "../../assets/images/logo.png";
import SideDrawerMenu from "./SideDrawerMenu";
import { connect } from "react-redux";
import { HEADER } from "../../constants/data";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../store/authentication/action";

const Header = ({
  classes,
  auth,
  userDetails,
  changeLanguage,
  selectedLanguage,
}) => {
  const [IsDrawerOpen, setIsDrawerOpen] = useState(false);
  const [OpenMenu, setOpenMenu] = useState(null);

  const { i18n } = useTranslation();

  //to handle drawer toggle
  const handleDrawer = () => {
    setIsDrawerOpen(!IsDrawerOpen);
  };

  const handleMenuOpen = (e) => {
    setOpenMenu(e.currentTarget);
  };

  const handleMenuClose = () => {
    setOpenMenu(null);
  };

  const handleLangSelected = (data) => {
    handleMenuClose();
    changeLanguage(data);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 100);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage && selectedLanguage.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  //to get the user image without error
  let image =
    userDetails && userDetails.userData && userDetails.userData.imgData;
  return (
    // <I18nextProvider i18n={i18n}>
    <AppBar position="static" className={classes.header}>
      <Toolbar>
        <div
          className={`${classes.container} ${
            auth ? classes.authContainer : ""
          }`}
        >
          {auth && (
            <Avatar
              className={classes.avatar}
              onClick={handleDrawer}
              src={
                image && image[0] === "b"
                  ? image
                  : `https://siera-safety.s3.us-east-2.amazonaws.com/user/${image}`
              }
            />
          )}
          <img src={Logo} alt="SIERA.AI" className={classes.image} />
          {/* {auth && ( */}
          <div
            className={`${classes.dropMenu} ${!auth ? classes.authMargin : ""}`}
          >
            <img
              src={selectedLanguage && selectedLanguage.flag}
              alt={selectedLanguage && selectedLanguage.title}
              className={classes.flag}
            />
            <Typography className={classes.lang}>
              {selectedLanguage && selectedLanguage.title}
            </Typography>
            <KeyboardArrowDown
              onClick={handleMenuOpen}
              className={classes.dropDown}
            />
          </div>
          {/* )} */}
        </div>
        <SideDrawerMenu
          isDrawerOpen={IsDrawerOpen}
          handleDrawer={handleDrawer}
          userDetails={userDetails}
        />
        <Menu
          id="simple-menu"
          anchorEl={OpenMenu}
          keepMounted
          open={Boolean(OpenMenu)}
          onClose={handleMenuClose}
        >
          {HEADER.map((flags) => (
            <div
              className={classes.dropMenus}
              onClick={() => handleLangSelected(flags)}
              key={flags.id}
            >
              <img
                src={flags.flag}
                alt={flags.title}
                className={classes.flag}
              />
              <Typography className={classes.menuLang}>
                {flags.title}
              </Typography>
            </div>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
    // </I18nextProvider>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.auth.UserDetails,
  selectedLanguage: state.auth.SelectedLanguage,
});
const mapDispatchToProps = (dispatch) => ({
  changeLanguage: (data) => dispatch(changeLanguage(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(Header));
