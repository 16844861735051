import React, { useState } from "react";
import {
  withStyles,
  Typography,
  TextField,
  Card,
  Button,
  Avatar,
  InputAdornment,
} from "@material-ui/core";
import { Styles } from "./styles";
import { connect } from "react-redux";
import {
  loginUser,
  resetMessegaes,
} from "../../../store/authentication/action";
import Loader from "../../../components/Loader";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import SnackBar from "../../../components/SnackBar";
import { Trans, useTranslation } from "react-i18next";

const Login = ({
  classes,
  assetName,
  assetUID,
  accountID,
  image,
  loginUser,
  scanLoading,
  error,
  resetMessegaes,
}) => {
  const [PIN, setPIN] = useState("");
  const [IsForgotPin, setIsForgotPin] = useState(false);
  const [IsSubmitted, setIsSubmitted] = useState(false);
  const [ShowPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();

  //To show the forgot popup
  const handleForgotPin = () => {
    setIsForgotPin(true);
  };

  //To navigate back to login
  const backToLogin = () => {
    setIsForgotPin(false);
  };

  //To navigagte to inspection page
  const handleNavigate = () => {
    setIsSubmitted(true);
    if (PIN) {
      let data = {
        PIN,
      };
      loginUser(data, "QR");
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!ShowPassword);
  };

  const handleCloseSnackBar = () => {
    resetMessegaes();
  };

  const handleMessage = (error) => {
    if (error) {
      return "Please enter correct PIN";
    }
  };

  return (
    <div className={classes.root}>
      {scanLoading && <Loader />}
      <Card className={classes.card} elevation={0}>
        {IsForgotPin ? (
          <>
            <Typography className={classes.contact}>
              <Trans>Contact your Manager to request your PIN</Trans>
            </Typography>
            <Button fullWidth className={classes.button} onClick={backToLogin}>
              <Trans>BACK TO LOGIN</Trans>
            </Button>
          </>
        ) : (
          <>
            <div className={classes.cardAvatar}>
              <Avatar
                className={classes.avatar}
                sr={`https://siera-safety.s3.us-east-2.amazonaws.com/asset/${image}`}
              />
              <div className={classes.details}>
                <Typography className={classes.assetName}>
                  {assetName}
                </Typography>
                <Typography className={classes.assetDetails}>
                  {assetUID}
                </Typography>
                <Typography className={classes.assetDetails}>
                  {accountID}
                </Typography>
              </div>
            </div>
            <TextField
              placeholder="PIN"
              value={PIN}
              type={ShowPassword ? "text" : "password"}
              onChange={(e) => setPIN(e.target.value)}
              className={classes.field}
              fullWidth
              helperText={IsSubmitted && !PIN && `${t("PIN is required")}`}
              error={IsSubmitted && !PIN}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className={classes.textIcon}>
                    {ShowPassword ? (
                      <Visibility onClick={handleShowPassword} />
                    ) : (
                      <VisibilityOff onClick={handleShowPassword} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <Button
              fullWidth
              className={classes.button}
              onClick={handleNavigate}
            >
              <Trans>LOGIN</Trans>
            </Button>
            <Typography className={classes.forgot} onClick={handleForgotPin}>
              <Trans>FORGOT PIN</Trans>?
            </Typography>
          </>
        )}
      </Card>
      <SnackBar
        isFailed={error}
        label={handleMessage(error)}
        handleClose={handleCloseSnackBar}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  scanLoading: state.auth.ScanLoading,
  error: state.auth.Error,
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (value, type) => dispatch(loginUser(value, type)),
  resetMessegaes: () => dispatch(resetMessegaes()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(Login));
