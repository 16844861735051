import React, { useEffect } from "react";
import { withStyles, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Link } from "react-router-dom";
import QrReader from "react-qr-reader";
import { connect } from "react-redux";
import { Styles } from "./styles";
import Layout from "../../layout";
import { getAAsset, toResetAAsset } from "../../store/assets/action";
import Loader from "../../components/Loader";
import { selectedAssetCheckLists } from "../../store/checkList/action";
import SnackBar from "../../components/SnackBar";
import { Trans } from "react-i18next";

const ScanAsset = ({
  classes,
  singleAssetLoading,
  getAAsset,
  selectedAssetCheckLists,
  singleAsset,
  singleAssetError,
  toResetAAsset,
}) => {
  const handleScan = (data) => {
    if (data) {
      getAAsset(data, "auth");
    }
  };
  const handleError = (err) => {};

  useEffect(() => {
    if (singleAsset) {
      selectedAssetCheckLists(singleAsset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleAsset]);

  //QR Code Styles
  const previewStyle = {
    width: "100%",
  };

  const handleCloseSnackBar = () => {
    toResetAAsset();
  };

  const handleMessage = (singleAssetError) => {
    if (singleAssetError) {
      return "Please scan correct Asset QR";
    }
  };
  return (
    <Layout>
      <div className={classes.root}>
        {singleAssetLoading && <Loader />}
        <div className={classes.titleBar}>
          <Link to="/asset-list">
            <ArrowBack className={classes.icon} />
          </Link>
          <Typography className={classes.text}>
            <Trans>Scan Asset</Trans>
          </Typography>
        </div>
        <div>
          <QrReader
            delay={2000}
            style={previewStyle}
            onError={handleError}
            onScan={handleScan}
          />
          <Typography className={classes.details}>
            <Trans>Please scan the QR code on the asset</Trans>
          </Typography>
        </div>
      </div>
      <SnackBar
        isFailed={singleAssetError}
        label={handleMessage(singleAssetError)}
        handleClose={handleCloseSnackBar}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  singleAsset: state.asset.SingleAsset,
  singleAssetLoading: state.asset.SingleAssetLoading,
  singleAssetError: state.asset.SingleAssetError,
});

const mapDispatchToProps = (dispatch) => ({
  getAAsset: (id, type) => dispatch(getAAsset(id, type)),
  selectedAssetCheckLists: (value) => dispatch(selectedAssetCheckLists(value)),
  toResetAAsset: () => dispatch(toResetAAsset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(ScanAsset));
