export const Styles = (theme) => ({
  root: {
    marginTop: 36,
  },
  subTitle: {
    fontSize: 27,
    color: theme.palette.primary.main,
    "& span": {
      display: "block",
      fontSize: 18,
      color: "#323232",
      marginTop: -2,
    },
  },
  titleBar: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    padding: "12px 5px 6px 15px",
  },
  icon: {
    color: theme.palette.common.white,
    border: "1px solid",
    borderRadius: 50,
    fontSize: 22,
  },
  text: {
    color: theme.palette.common.white,
    fontSize: 16,
    paddingLeft: 10,
    marginTop: -5,
  },
  details: {
    color: theme.palette.common.black,
    fontSize: 15,
    fontFamily: "Open Sans",
    marginTop: 22,
    width: "65%",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  container: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  scan: {
    width: 84,
    height: 84,
    fontSize: 19,
    border: "2px dashed #000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.black,
  },
  scanCover: {
    marginTop: 50,
    height: 204,
    width: 204,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    background: `linear-gradient(to right, ${theme.palette.primary.main} 2px, transparent 2px) 0 0,
    linear-gradient(to right, ${theme.palette.primary.main} 2px, transparent 2px) 0 100%,
    linear-gradient(to left, ${theme.palette.primary.main} 2px, transparent 2px) 100% 0,
    linear-gradient(to left, ${theme.palette.primary.main} 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, ${theme.palette.primary.main} 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, ${theme.palette.primary.main} 2px, transparent 2px) 100% 0,
    linear-gradient(to top, ${theme.palette.primary.main} 2px, transparent 2px) 0 100%,
    linear-gradient(to top, ${theme.palette.primary.main} 2px, transparent 2px) 100% 100%`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "20px 20px",
  },
  detailsQR: {
    textAlign: "center",
    margin: "0 auto",
    marginTop: 30,
    fontSize: 20,
    width: "80%",
  },
  other: {
    marginTop: 74,
    "& img": {
      width: 50,
      cursor: "pointer",
    },
  },
  content: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
});
