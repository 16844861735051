export const Styles = (theme) => ({
  container: {
    width: "75%",
    margin: "0 auto",
    textAlign: "center",
    marginTop: 38,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  pass: {
    fontSize: 32,
    color: "#11A70B",
    fontFamily: "Kanit",
    fontWeight: 200,
  },
  fail: {
    fontSize: 32,
    color: "#DD0000",
    fontFamily: "Kanit",
    fontWeight: 200,
  },
  message: {
    color: "#323232",
    fontSize: 16,
  },
  tipContainer: {
    margin: "20px 0px",
    minHeight: "50px",
    boxShadow: "1px 1px 26px -2px rgba(0,0,0,0.58);",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderRadius: "10px",
  },
  leftContent: {
    backgroundColor: "rgb(244, 244, 244)",
    padding: "10px 5px",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    color: theme.palette.primary.main,
  },
  rightContent: {
    padding: "10px",
    textAlign: "left",
  },
  tipHeading: {
    color: theme.palette.primary.main,
    margin: "0px",
    fontFamily: "Kanit",
    fontSize: 18,
  },
  tipBody: {
    fontSize: 14,
    fontWeight: 100,
  },
  row: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    marginTop: "10px",
  },
  hr: {
    width: "100%",
    borderTop: "1px solid #000",
  },
  circle: {
    height: "10px",
    width: "20px",
    borderRadius: "20px",
    border: "1px solid #000",
  },
  avatar: {
    width: 160,
    height: 160,
    marginTop: 26,
    marginBottom: 20,
  },
  name: {
    fontSize: 24,
    color: "#323232",
  },
  id: {
    fontSize: 18,
    color: "#323232",
  },
  inspection: {
    color: theme.palette.common.white,
    fontSize: 16,
    textDecoration: "none",
    width: "70%",
    margin: "0 auto",
    border: `1px solid rgba(13, 95, 190, 0.5)`,
    display: "block",
    padding: "10px 0",
    borderRadius: 4,
    fontFamily: "Open Sans",
    backgroundColor: theme.palette.primary.main,
  },
  logout: {
    fontSize: 16,
    marginTop: 16,
    width: "70%",
  },
  buttons: { marginTop: 36, width: "100%" },
});
