export const Styles = (theme) => ({
  titleBar: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    padding: "12px 5px 6px 15px",
  },
  icon: {
    color: theme.palette.common.white,
    border: "1px solid",
    borderRadius: 50,
    fontSize: 22,
  },
  text: {
    color: theme.palette.common.white,
    fontSize: 16,
    paddingLeft: 10,
    marginTop: -5,
  },
  details: {
    textAlign: "center",
    margin: "0 auto",
    marginTop: 30,
    fontSize: 20,
    width: "80%",
  },
});
