import React, { useState, useEffect } from "react";
import {
  withStyles,
  Typography,
  Avatar,
  TextField,
  Button,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { connect } from "react-redux";
import { Styles } from "./styles";
import { Link } from "react-router-dom";
import Layout from "../../layout";
import { updateProfile } from "../../store/authentication/action";
import Loader from "../../components/Loader";
import EditImage from "../../assets/images/White_Save_Submit.png";
import history from "../../constants/history";
import { Trans, useTranslation } from "react-i18next";

const EditProfile = ({
  classes,
  userDetails,
  updateProfile,
  updateLoading,
  updateSuccess,
}) => {
  const [FullName, setFullName] = useState("");
  const [Phone, setPhone] = useState("");
  const [SelectedFile, setSelectedFile] = useState(null);
  const [PreviewImage, setPreviewImage] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    handleDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //To handle image upload
  const handleImage = (e) => {
    setSelectedFile(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };

  const handlePhoneNumber = (e) => {
    if (e.target.value.length < 11) {
      setPhone(e.target.value);
    }
  };

  useEffect(() => {
    if (updateSuccess) {
      history.push("/user-profile");
    }
  }, [updateSuccess]);

  //To submit the details after user entered and to call the api
  const handleSubmit = () => {
    let data = {
      FullName,
      Phone,
      SelectedFile,
      PreviewImage,
    };
    updateProfile(data);
  };

  //To handle the data update
  const handleDefault = () => {
    let name = userDetails?.userData?.fullName;
    let phone = userDetails?.userData?.phone;
    let image = userDetails?.userData?.imgData;

    setFullName(name);
    setPhone(phone);
    setPreviewImage(image);
  };

  //To cancel
  const handleCancel = () => {
    handleDefault();
    history.push("/user-profile");
  };
  return (
    <Layout auth={true}>
      <div className={classes.titleBar}>
        {updateLoading && <Loader />}
        <Link to="/user-profile">
          <ArrowBack className={classes.icon} />
        </Link>
        <Typography className={classes.text}>
          <Trans>Edit Profile</Trans>
        </Typography>
      </div>
      <div className={classes.content}>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={handleImage}
        />

        <div className={classes.imageContainer}>
          <Avatar
            className={classes.image}
            src={
              PreviewImage && PreviewImage[0] === "b"
                ? PreviewImage
                : `https://siera-safety.s3.us-east-2.amazonaws.com/user/${PreviewImage}`
            }
          />
          <div className={classes.overlayImage}>
            <label htmlFor="contained-button-file">
              <Typography className={classes.imageText}>
                <Trans> Change Photo</Trans>
              </Typography>
            </label>
          </div>
        </div>

        <TextField
          variant="filled"
          label={t("First and Last Name")}
          className={classes.field}
          onChange={(e) => setFullName(e.target.value)}
          fullWidth
          value={FullName}
        />

        <TextField
          variant="filled"
          label={t("Phone")}
          type="number"
          className={classes.field}
          onChange={handlePhoneNumber}
          fullWidth
          value={Phone}
        />
        <div className={classes.buttons}>
          <Button
            className={classes.save}
            startIcon={
              <img src={EditImage} alt="Save" className={classes.saveImage} />
            }
            onClick={handleSubmit}
          >
            <Trans>SAVE</Trans>
          </Button>
          <Button className={classes.cancel} onClick={handleCancel}>
            <Trans>CANCEL</Trans>
          </Button>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.auth.UserDetails,
  updateLoading: state.auth.UpdateLoading,
  updateSuccess: state.auth.UpdateSuccess,
});
const mapDispatchToProps = (dispatch) => ({
  updateProfile: (value) => dispatch(updateProfile(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(EditProfile));
