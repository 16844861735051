import Flag from "../../assets/images/flag/us-flag.svg";
import ESFLAG from "../../assets/images/flag/es-flag.svg";

//For Header
export const HEADER = [
  { id: 0, title: "EN", flag: Flag, value: "en" },
  { id: 1, title: "ES", flag: ESFLAG, value: "es" },
];

//For Inspection Options
export const OPTIONS = {
  0: "Not Ok",
  1: "Ok",
  2: "NA",
  3: "Numeric",
  4: "Text",
};
