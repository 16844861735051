export const Styles = (theme) => ({
  titleBar: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    padding: "12px 5px 6px 15px",
  },
  icon: {
    color: theme.palette.common.white,
    border: "1px solid",
    borderRadius: 50,
    fontSize: 22,
  },
  text: {
    color: theme.palette.common.white,
    fontSize: 16,
    paddingLeft: 10,
    marginTop: -5,
  },
  content: {
    marginTop: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  image: {
    height: 117,
    width: 117,
    marginBottom: 20,
  },
  name: {
    color: "#2B2B2B",
    fontSize: 24,
  },
  job: {
    color: "#464646",
    border: "1px solid",
    borderRadius: 50,
    marginTop: 17,
    fontSize: 15,
    padding: "6px 26px",
    textTransform: "capitalize",
  },
  details: {
    marginTop: 16,
  },
  email: {
    backgroundColor: "#F9F9F9",
    color: "#919191",
    fontSize: 16,
    marginBottom: 16,
    padding: "10px 15px",
    "& span": {
      display: "block",
      color: "#323232",
      fontSize: 18,
    },
  },
  phone: {
    backgroundColor: "#F9F9F9",
    color: "#919191",
    fontSize: 16,
    padding: "10px 15px",
    "& span": {
      display: "block",
      color: "#323232",
      fontSize: 18,
    },
  },
  edit: {
    marginTop: 32,
    fontSize: 16,
    padding: "10px 30px",
    border: "1px solid",
    color: theme.palette.primary.main,
    fontFamily: "Open Sans",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "16%",
    borderRadius: 4,
    "& img": {
      paddingRight: 10,
      width: "30%",
    },
  },
});
