export const Styles = (theme) => ({
  container: {
    marginTop: 26,
    textAlign: "center",
    width: "80%",
    margin: "0 auto",
  },
  title: {
    fontSize: 16,
    fontFamily: "Open Sans",
    fontWeight: 600,
  },
  field: {
    marginTop: 20,
    border: "1px solid #c6c6c6",
    "& .MuiSelect-outlined": {
      fontSize: 18,
      textAlign: "left",
    },
    "& label": {
      fontSize: 20,
      width: 229,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  button: {
    marginTop: 100,
    height: 224,
    width: 224,
    fontSize: 26,
    background: `radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%), linear-gradient(to top right, #ffb000 100%, #C99217 0%)`,
    borderRadius: "225px",
    color: "#323232",
    boxShadow: "0px 6px 20px rgba(0,0,0,0.6)",
  },
});
