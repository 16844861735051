const initialState = {
  Loading: false,
  Success: false,
  Error: false,
  AssetTypes: null,
  AssetLoading: false,
  AssetSuccess: true,
  AssetError: false,
  Assets: null,
  SingleAssetLoading: false,
  SingleAssetSuccess: false,
  SingleAssetError: false,
  SingleAsset: null,
};

export default initialState;
