const LOGIN_USER_LOADING = "LOGIN_USER_LOADING";
const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
const GET_ACCOUNT_DETAILS_LOADING = "GET_ACCOUNT_DETAILS_LOADING";
const GET_ACCOUNT_DETAILS_SUCCESS = "GET_ACCOUNT_DETAILS_SUCCESS";
const GET_ACCOUNT_DETAILS_ERROR = "GET_ACCOUNT_DETAILS_ERROR";
const UPDATE_PROFILE_LOADING = "UPDATE_PROFILE_LOADING";
const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
const USER_LOGOUT = "USER_LOGOUT";
const RESET_MESSAGES = "RESET_MESSAGES";
const LOGIN_SCANNER_ACCOUNTID = "LOGIN_SCANNER_ACCOUNTID";
const SCAN_BADGE_LOADING = "SCAN_BADGE_LOADING";
const SCAN_BADGE_SUCCESS = "SCAN_BADGE_SUCCESS";
const SCAN_BADGE_ERROR = "SCAN_BADGE_ERROR";
const LANGUAGE_TRANSLATION = "LANGUAGE_TRANSLATION";
const GET_INSPECTION_COUNT_LOADING = "GET_INSPECTION_COUNT_LOADING";
const GET_INSPECTION_COUNT_SUCCESS = "GET_INSPECTION_COUNT_SUCCESS";
const GET_INSPECTION_COUNT_ERROR = "GET_INSPECTION_COUNT_ERROR";

export {
  LOGIN_USER_LOADING,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  GET_ACCOUNT_DETAILS_LOADING,
  GET_ACCOUNT_DETAILS_SUCCESS,
  GET_ACCOUNT_DETAILS_ERROR,
  UPDATE_PROFILE_LOADING,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  USER_LOGOUT,
  RESET_MESSAGES,
  LOGIN_SCANNER_ACCOUNTID,
  SCAN_BADGE_LOADING,
  SCAN_BADGE_SUCCESS,
  SCAN_BADGE_ERROR,
  LANGUAGE_TRANSLATION,
  GET_INSPECTION_COUNT_LOADING,
  GET_INSPECTION_COUNT_SUCCESS,
  GET_INSPECTION_COUNT_ERROR,
};
