const GET_ASSET_TYPE_LOADING = "GET_ASSET_TYPE_LOADING";
const GET_ASSET_TYPE_SUCCESS = "GET_ASSET_TYPE_SUCCESS";
const GET_ASSET_TYPE_ERROR = "GET_ASSET_TYPE_ERROR";
const GET_ALL_ASSET_LOADING = "GET_ALL_ASSET_LOADING";
const GET_ALL_ASSET_SUCCESS = "GET_ALL_ASSET_SUCCESS";
const GET_ALL_ASSET_ERROR = "GET_ALL_ASSET_ERROR";
const GET_A_ASSET_LOADING = "GET_A_ASSET_LOADING";
const GET_A_ASSET_SUCCESS = "GET_A_ASSET_SUCCESS";
const GET_A_ASSET_ERROR = "GET_A_ASSET_ERROR";
const TO_RESET_ALL_ASSET_STATE = "TO_RESET_ALL_ASSET_STATE";
const RESET_A_ASSET_STATE = "RESET_A_ASSET_STATE";

export {
  GET_ASSET_TYPE_LOADING,
  GET_ASSET_TYPE_SUCCESS,
  GET_ASSET_TYPE_ERROR,
  GET_ALL_ASSET_LOADING,
  GET_ALL_ASSET_SUCCESS,
  GET_ALL_ASSET_ERROR,
  GET_A_ASSET_LOADING,
  GET_A_ASSET_SUCCESS,
  GET_A_ASSET_ERROR,
  TO_RESET_ALL_ASSET_STATE,
  RESET_A_ASSET_STATE,
};
